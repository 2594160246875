import React from 'react';
import {makeStyles} from '@material-ui/core';
import {connect} from "react-redux";
const useStyles = makeStyles(theme => ({
    h1:{
        textTransform: "uppercase",
        textAlign: "center",
        color: theme.palette.secondary.main,
        paddingTop: "7%",
        [theme.breakpoints.down('sm')]: {
            fontSize: "1.3em",
        }
    },
}));
function DashBoard({user}) {
    const {usuNombres,usuApellidos} = user
    const classes = useStyles();
    return (
        <>
            <div className={"contentInner"}>
                <div>
                    <h1 className={classes.h1}>Bienvenido, {usuNombres} {usuApellidos}</h1>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => ({
    user: state.auth.user,
});
export default connect(mapStateToProps)(DashBoard);

