import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {mostrarClientes,mostrarClienteById} from "../../redux/actions/clientesActions";
import {useDispatch, useSelector} from 'react-redux';

import {
    Grid, FormControl, List, useMediaQuery, makeStyles, Dialog, DialogContent, InputLabel, DialogActions, Button,
    Input, TableRow, TableCell, TableBody, Badge, Avatar, Typography
} from '@material-ui/core';
import {
    ClientCard, ClientListItem, AppBar, SeacrhAppBar, PlusFab, ListViewMode, LoadingProgress, Pagination, TableSort,
    ButtonResponsive, FilteredAlert, MostrarError
} from '../../components';
import {SelectSmart} from "../../components/CustomTextField";
import Strings from "../../assets/strings";
import ContactDetails from "./ContactDetails";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {CloudDownloadOutlined, FilterList} from "@material-ui/icons";
import downloadFiles from "../../helpers/downloadFiles";
import SelectUsoMultiples from "../../components/selects/SelectUsoMultiples";
import DefaultImgEmp from "../../assets/img/defaultAvatar_empresa.jpg";
import NoImg from "../../assets/img/defaultAvatar.jpg";
const useStyles = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    item:{
        [theme.breakpoints.down('xs')]: {
            padding: "0px !important",
        },
    },
    status:{
        display: "block",
        width: 10,
        height: 10,
        background: "gray",
        border: "2px solid #fff",
        borderRadius: "50%",
        position: "absolute",
        right: 5,
        bottom: 5,
        "&.e0":{
            background: "red"
        },
        "&.e1":{
            background: "#01a855"
        },
        "&.e2":{
            background: "orange"
        }
    },
    nombre:{
        "&>div":{
            display: "flex",
            alignItems: "center",
            "&>span":{
                marginRight: 8,
                //  float: "left",
                "&>div":{
                    height: 31,
                    width: 31,
                }
            },
            "& p":{
                lineHeight: 1,
            },
            "& .MuiRating-decimal":{
                fontSize: 14,
            }
        }
    }
}));

const initialState={
    name: '',
    tipo:"",
    estado: "",
    page:1,
    pageSize:15,
    orderBy:"",
    orderByIsDescending:""
};
export  default function Clientes(){
    let history = useHistory();
    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));
    const classes = useStyles();

    const clientes = useSelector(state=>state.clientes);
    const viewTableList = useSelector(state=>state.theme.viewTableList);
    const Dispatch = useDispatch();


    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useState(initialState);


    const { list, isFetching,filtered,error}  = clientes;
    const isEmpty = (!list.results || list.results.length === 0);

    useEffect(() => {
        Dispatch(mostrarClientes());
    }, [Dispatch]);
    //set filtered params
    useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

    const handleFilter = e => {
        const {name, value} = e.target;
        if(name === "name" || name === "page" || name === "pageSize"){
            return Dispatch(mostrarClientes({
                ...filter,
                page:null,
                ...(name === "name" && {estado: null}),
                [name]:value
            }));
        }
        setFilter({...filter,page:null,[name]:value})
    };
    const resetFilter = () => {
        setOpenFilter(false);
        setFilter(initialState);
        Dispatch(mostrarClientes({}));
    };
    const searchFilter = () => {
        setOpenFilter(false);
        Dispatch(mostrarClientes(filter));
    };
    const tableSortProps = orderBy => {
        return {
            active:filter.orderBy === orderBy,
            direction: filter.orderByIsDescending ? "desc" : 'asc',
            onClick:()=>Dispatch(mostrarClientes({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending}))
        };
    };
    const showClientModal = cliId => {
        Dispatch(mostrarClienteById(cliId));
        history.push(history.location.pathname, {cliId});
    };

    return (
        <>
            <AppBar title={Strings.clientes}>
                <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
                <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(true)}/>
                <ButtonResponsive style={{display:"none"}} icon={<CloudDownloadOutlined/>} text={Strings.exportar} disabled={isEmpty} onClick={()=>downloadFiles(filter,"clientes")}/>
            </AppBar>
            <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Estado</InputLabel>
                                <SelectSmart
                                    value={filter.estado}
                                    onChange={handleFilter}
                                    input={<Input name="estado" />}
                                    opciones={[
                                        {key:'',value:Strings.seleccione},
                                        {key:'1',value:Strings.activo},
                                    ]}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectUsoMultiples
                                emptyOption={{key:"",value:Strings.todo}}
                                name="tipo" value={filter.tipo} label={Strings.tipo} group="CliTipo" onChange={handleFilter} />
                        </Grid>
                    </Grid>
                    <br/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpenFilter(false)}>{Strings.cancelar}</Button>
                    <Button color="primary" variant={"contained"} disableElevation onClick={searchFilter}>oK</Button>
                </DialogActions>
            </Dialog>
            <ListViewMode pageRows={list.pageSize} pageTotalRows={list.totalRecordCount} lg>
                <FilteredAlert onClose={resetFilter} filter={filtered}/>
            </ListViewMode>
            <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
                <MostrarError errors={error}/>
                {isFetching && isEmpty && <LoadingProgress vh/>}

                {viewTableList?(
                    <TableSort data={[
                        {pro:"cliNombres",text:Strings.nombreCompleto},
                        {pro:"cliCedula",text:Strings.cedula},
                        {pro:"cliTelefono",text:Strings.telefono},
                        {pro:"cliEmail",text:Strings.email},
                    ]} onSort={tableSortProps} lessVh={205} >
                        <TableBody>
                            {!isEmpty && list.results.map(cliente =>
                                <TableRow key={cliente.cliId} onClick={()=>showClientModal(cliente.cliId)}>
                                    <TableCell className={classes.nombre}>
                                            <div>
                                                <Badge
                                                    classes={{badge:classes.status +" e"+ cliente.cliEstado}}
                                                    color={"secondary"}
                                                    variant={"dot"}
                                                    overlap="circle"
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <Avatar alt="" src={ cliente.cliAvatar?cliente.cliAvatar: (cliente.cliTipo === 2 ?DefaultImgEmp: NoImg)}/>
                                                </Badge>
                                                <div>
                                                    <Typography>{cliente.cliNombres +" "+cliente.cliApellidos}</Typography>
                                                    <Typography variant={"caption"}>{cliente.cliTipoDescripcion}</Typography>

                                                </div>
                                            </div>
                                        </TableCell>

                                    {/*<TableCell>{cliente.fullName}</TableCell>*/}
                                    <TableCell>{cliente.cliCedula}</TableCell>
                                    <TableCell>{cliente.cliTelefono}</TableCell>
                                    <TableCell>{cliente.cliEmail}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </TableSort>
                ):(
                    smVewport ?(
                        <List>
                            {!isEmpty && list.results.map(cliente => (
                                <ClientListItem key={cliente.cliId} cliente={cliente} onClick={()=>
                                    showClientModal(cliente.cliId)
                                } />
                            ))}
                        </List>
                    ):(
                        <Grid container spacing={3}>
                            {!isEmpty && list.results.map(cliente => (
                                <Grid key={cliente.cliId} item xs={12} sm={6} md={4} lg={3} className={classes.item}>
                                    <ClientCard  onClick={()=>showClientModal(cliente.cliId)} cliente={cliente} />
                                </Grid>
                            ))}
                        </Grid>
                    )
                )}

                <Pagination
                    empty={Boolean(isEmpty && !isFetching)}
                    count={list.totalRecordCount || 0}
                    rowsPerPage={filter.pageSize}
                    page={list.pageNo -1}
                    onChangePage={(e,page)=>handleFilter({target:{name:"page",value:page+1}})}

                    //  onChangePage={(e,page)=> setFilter({...filter,page:page+1})}
                    onChangeRowsPerPage={handleFilter}
                    //onChangeRowsPerPage={(e)=>  setFilter({...filter,pageSize:parseInt(e.target.value)})}
                />


                <ContactDetails />
            </section>
            {hasPermissionTo(PERSMISOS.clientesCrear) &&
            <PlusFab component={Link} to="/CrearCliente" />
            }

        </>
    );
}
