import React, { useEffect} from 'react';
import { connect } from 'react-redux';
import { mostrarGrupos, eliminarGrupo} from '../../redux/actions/gruposActions';
import AppBar from "../../components/appBar";
import Crear  from './crear';
import {
    ListItem,
    List,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Button,
    ListItemIcon
} from "@material-ui/core";
import PlusFab from "../../components/PlusFab";
import {Close} from "@material-ui/icons";
import LoadingProgress from "../../components/LoadingProgress";
import {useHistory, useLocation} from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import {MostrarError} from "../../components/MostrarError";
import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {Empty} from "../../components";
import {ReactComponent as EmptySvg} from "../../assets/img/map.svg";
import {getIcon} from "../../assets/iconPath";

function Grupos(props) {
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();

    const { mostrarGrupos } = props;
    useEffect(() => {
        mostrarGrupos();
    }, [mostrarGrupos]);

    const { grupos: { list, isFetching, error } } = props;
    const isEmpty = !isFetching && list.length === 0;

    return (
        <>
            <AppBar title={Strings.grupos}/>
            <section className="contentInner" style={!isEmpty ? {maxWidth:600}:{}}>
                {!urlState && <MostrarError errors={error}/>}
                {isFetching  && !list.length && <LoadingProgress vh/>}

                <List >
                    {!isEmpty && list.map(item =>
                        <ListItem role={undefined} button key={item.gruId}
                                  onClick={()=>{
                                      if(!hasPermissionTo(PERSMISOS.gruposActualizar)) return false;
                                      history.push(history.location.pathname,item)}}>
                            <ListItemIcon>
                                <svg className="MuiSvgIcon-root jss67"  style={{color:item.gruColor?item.gruColor:"auto"}}
                                     viewBox="0 0 24 24" aria-hidden="true" tabIndex="-1"
                                     data-ga-event-action="click">
                                    <path d={getIcon(item.gruIcon || "Place")}></path>
                                </svg>
                            </ListItemIcon>
                            <ListItemText primary={item.gruDescripcion} style={{color:item.gruColor?item.gruColor:"auto"}}/>
                            {hasPermissionTo(PERSMISOS.gruposEliminar) && !item.registroDelSistema &&
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments" onClick={()=>history.push(history.location.pathname,{confirmDialog:item.gruId})} >
                                    <Close className="text-red" fontSize={"small"}/>
                                </IconButton>
                            </ListItemSecondaryAction>
                            }
                        </ListItem>
                    )}
                </List>
                {isEmpty &&
                <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.grupos}>
                    <p>{Strings.gruposHelpMsj}</p>
                </Empty>
                }


                {hasPermissionTo(PERSMISOS.gruposCrear) &&
                <PlusFab component={Button} onClick={()=>history.push(history.location.pathname,{gruId:""})} />
                }
                <Crear/>


                <ConfirmDialog onConfirm={()=>props.eliminarGrupo(urlState && urlState.confirmDialog)}/>

            </section>
        </>
    );

}

const mapStateToProps = (state) => ({
    grupos: state.grupos
});
export default connect(mapStateToProps, { mostrarGrupos, eliminarGrupo })(Grupos);