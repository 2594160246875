import React, {Fragment, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { agregarEditarCliente, mostrarClienteById } from '../../redux/actions/clientesActions';
import {nullToEmptyString} from '../../helpers';
import {Grid,TextField, Button, CircularProgress,
    makeStyles }from '@material-ui/core';
import {AppBar,MostrarError,LoadingProgress} from "../../components";
import ReferenciasCliente from "../../components/referenciaCliente";

import SelectFiles from "../../components/selects/SelectFiles";
import SelectUsoMultiples from "../../components/selects/SelectUsoMultiples";
import {useCustomFormInput} from "../../hooks/useCustomFormInput";

import {TextFieldPhone} from "../../components/CustomTextField";
import Strings from "../../assets/strings";
import AddAdjuntos from "../../components/AddAdjuntos";
import MapSearch from "../../components/MapSearch";
const useStyles = makeStyles(theme => ({
    root:{
        padding:"20px",

    },
    avatar:{
        "& img":{
            maxWidth: 150
        },
        [theme.breakpoints.down('sm')]: {
            "& img":{
                borderRadius: "50%",
            }
        }

    },
    submitBtn:{
        margin: 20,
    },
}));
let initialState = {
    cliId:"",
    cliTipo:"",
    cliNombres:"",
    cliApellidos:"",
    cliCedula:"",
    cliTelefono:"",
    cliCelular:"",
    cliEmail:"",

    cliAvatar:"",
    cliDireccion: "",
    cliDireccion2: "",
    cliCoordenadas:"",

    paiNacionalidad:"12",
    adjuntos:[],
    referencias: []
}
function CrearCliente(props){
    const classes = useStyles();
    const customFormInput = useCustomFormInput();

    let { cliId:urlCliId} = useParams()
    const {agregarEditarCliente,mostrarClienteById, cliente, isFetching,isPending, error} = props;

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlCliId) mostrarClienteById(urlCliId);
    },[urlCliId,mostrarClienteById])

    useEffect(()=>{
        if(cliente?.cliId === parseInt(urlCliId)) {
            setState({...initialState, ...nullToEmptyString(cliente)});
        }else{
            setState(initialState)
        }
    },[urlCliId,cliente])
    const handleChange = (e)=>{
        const {name,value} = e.target
        return setState({...state,[name]: value});
    }
    const handleMapChange = (obj)=>{
        let dir={};
        if(obj.direccion) dir.cliDireccion = obj.direccion;
        if(obj.coordenadas) dir.cliCoordenadas = obj.coordenadas;
        return setState({...state,...dir});
    }
    const submit = (e) =>{
        e.preventDefault();
        let obj = {...state}

        if(obj.cliAvatar === cliente.cliAvatar )
            obj.cliAvatar = "";
        if(state.cliTipo === "1"){
            delete obj.cliDireccion;
            delete obj.cliDireccion2;
            delete obj.cliCoordenadas;
        }

        agregarEditarCliente(obj);
    }

    return(
        <Fragment>
            <AppBar title={state.cliId ? Strings.editar+" "+Strings.prestatario : Strings.crear+" "+Strings.prestatario } >
                <Button color="inherit" disabled={isPending} type="submit" form="form1" value="Submit">
                    {isPending? (
                        <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                    ):(
                        <span>{Strings.guardar}</span>
                    )}
                </Button>
            </AppBar>
            <div className={"contentInner " + classes.root}>
                <MostrarError errors={error}/>
                {(isFetching || isPending) && <LoadingProgress vh/>}

                <form id="form1" onSubmit={submit} autoComplete={"off"}>
                    <Grid container spacing={3} style={{marginBottom:16}}>
                        <Grid item xs={12} sm={2}>
                            <div className={classes.avatar}>
                                <SelectFiles src={state.cliAvatar ? state.cliAvatar : null}
                                             onChange={(baseb4)=>setState({ ...state, cliAvatar: baseb4 } )}/>

                            </div>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} md={3}>
                                     <SelectUsoMultiples name="cliTipo" value={state.cliTipo} group="CliTipo" onChange={handleChange} showAll={false} label={Strings.tipo} required/>
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        name={"cliNombres"}
                                        label={Strings.primerNombre}
                                        value={state.cliNombres}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        name="cliApellidos"
                                        label={Strings.apellidos}
                                        value={state.cliApellidos}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        name="cliCedula"
                                        label={Strings.cedula}
                                        value={state.cliCedula}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 20}}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextFieldPhone
                                        flag
                                        name="cliCelular"
                                        label={Strings.celular}
                                        value={state.cliCelular}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 20}}
                                        required={!state.cliTelefono}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} >
                                    <TextFieldPhone
                                        flag
                                        name="cliTelefono"
                                        label={Strings.telefono}
                                        value={state.cliTelefono}
                                        onChange={handleChange}
                                        required={!state.cliCelular}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} >
                                        <TextField
                                            name="cliEmail"
                                            label="Email"
                                            value={state.cliEmail}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            fullWidth
                                        />
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        {customFormInput && Object.keys(customFormInput).map((key)=>
                            <Grid item xs={12} sm={4} key={key}>
                                <TextField
                                    name={key}
                                    label={customFormInput[key].name}
                                    required={customFormInput[key].isRequired}
                                    value={state[key] ? state[key] : ""}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                    {state.cliTipo === "2" &&
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={9} md={7}>
                                <MapSearch handleChange={handleMapChange}
                                           direccion = {state.cliDireccion} coordenadas={state.cliCoordenadas}/>
                            </Grid>
                            <Grid item xs={12}  sm={5} md={5}>
                                <TextField
                                    name="cliDireccion2"
                                    label={Strings.direccion + " 2"}
                                    value={state.cliDireccion2}
                                    placeholder={Strings.referenciaDireccion}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 100}}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    }
                    {state.cliTipo !== "2" &&
                    <>
                        <br/>
                        <ReferenciasCliente referencias={state.referencias}
                        onChange={(referencias)=>setState({...state,referencias})} />
                    </>}
                    <br/>
                    <AddAdjuntos adjuntos={state.adjuntos} adjlimit={7}
                                 onChange={(adjuntos)=>setState({...state,adjuntos})} />

                </form>
                <br/>
            </div>
        </Fragment>
    );

}

const mapStateToProps = (state) => ({
    cliente: state.clientes.byId,
    isPending: state.clientes.isPending,
    isFetching: state.clientes.isFetching,
    error: state.clientes.error
})
export default connect(mapStateToProps, { agregarEditarCliente, mostrarClienteById })(CrearCliente);


