import React, {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {connect} from "react-redux";
import {mostrarPagos} from "../../redux/actions/pagosActions";

import {
    useMediaQuery, TextField, Grid, TableBody, TableRow, Button, Dialog, DialogContent,
    makeStyles, Checkbox, Typography, FormControlLabel, DialogActions, TableCell
} from "@material-ui/core";

import {formatPeso, formatDate} from '../../helpers';
import downloadFiles from '../../helpers/downloadFiles';
import ReciboPago from "../../components/recibo";
import {
    Pagination, TableSort, ButtonResponsive, SeacrhAppBar, LoadingProgress, CustomListItem, ListViewMode, AppBar,
    MostrarError, FilteredAlert} from "../../components";
import {CloudDownloadOutlined, FilterList} from "@material-ui/icons";
import Strings from '../../assets/strings'

const useStyles = makeStyles(theme => ({
    pagAnulado:{
        background: "#fff1f1",
        "& td, th":{
            color:theme.palette.error.light,
        }
    },
    pagAjuste:{
        background:"#d6f4ff !important"
    }
}));
let initialState={
    name:'',
    fechaDesde: '',
    fechaHasta: '',
    creadoPor: '',
    incluirAnulados:false,
    //pagination
    page: 0,
    pageSize:15,
    orderBy:"",
    orderByIsDescending:""
}
function PagosRealizados(props) {
    const {inmId} = useParams();
    if(inmId) initialState.inmId = inmId;

    let history = useHistory();
    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));
    const classes = useStyles();

    const [openFilter, setOpenFilter] = useState(false)
    const [filter, setFilter] = useState(initialState);

    const { mostrarPagos,pagos: { list, isFetching,error,filtered} } = props;
    const isEmpty = (!list.results || list.results.length === 0);

    useEffect(() => {
        mostrarPagos({inmId});
    }, [inmId,mostrarPagos]);
    //set filtered params
    useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])


    const handleFilter = e => {
        const {name, value,checked} = e.target;
        if(name === "name" || name === "page" || name === "pageSize") return mostrarPagos({...filter,page:null,[name]:value});

        if(name === "incluirAnulados")
            setFilter({...filter,page:null,[name]:checked});
        else
            setFilter({...filter,page:null,[name]:value});
    };
    const resetFilter = () => {
        setOpenFilter(false);
        mostrarPagos({});
    };
    const searchFilter = () => {
        setOpenFilter(false);
        mostrarPagos(filter);
    };
    const tableSortProps = orderBy => {
        return {
            active:filter.orderBy === orderBy,
            direction: filter.orderByIsDescending ? "desc" : 'asc',
            onClick:()=> mostrarPagos({...filter, orderBy,page:null, orderByIsDescending: !filter.orderByIsDescending})
        }
    }

    return (
        <>
            <AppBar title={"Pagos"}>
                <SeacrhAppBar style={{display:"none"}} name="name" value={filter.name} onChange={handleFilter}/>
                <ButtonResponsive  icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
                <ButtonResponsive style={{display:"none"}} icon={<CloudDownloadOutlined/>} text={Strings.exportar} disabled={isEmpty} onClick={()=>downloadFiles(filter,"pagos")}/>
            </AppBar>
            <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} maxWidth={"xs"}>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label={Strings.fechaDesde}
                                type="date"
                                name="fechaDesde"
                                value={filter.fechaDesde}
                                onChange={handleFilter}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <TextField
                                label={Strings.fechaHasta}
                                type="date"
                                name="fechaHasta"
                                value={filter.fechaHasta}
                                onChange={handleFilter}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <FormControlLabel
                            control={<Checkbox checked={filter.incluirAnulados} onChange={handleFilter} name="incluirAnulados" />}
                            label={Strings.ver+" "+Strings.anulado}
                            labelPlacement="start"
                        />
                    </Grid>
                    <br/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetFilter}>{Strings.cancelar}</Button>
                    <Button color="primary" onClick={searchFilter}>oK</Button>
                </DialogActions>
            </Dialog>
            <ListViewMode pageRows={list.pageSize} pageTotalRows={list.totalRecordCount}>
                <FilteredAlert onClose={resetFilter} filter={filtered}/>
            </ListViewMode>
            <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
                {isFetching && isEmpty && <LoadingProgress vh/>}

                {error && <MostrarError errors={error}/>}

                {(!props.viewTableList && smVewport)?(
                    !isEmpty && list.results.map(pag =>
                        <CustomListItem key={pag.pagId}
                                        onClick={()=> history.push(history.location.pathname,{recibo:pag})}
                                        lt={pag.cliente}
                                        lb={<Typography variant={"caption"}>{pag.conReferencia}</Typography>}
                                        rt={<Typography color={"primary"}>{formatPeso(pag.pagTotal)}</Typography>}
                                        rb={<Typography variant="caption">{formatDate(pag.pagFecha)}</Typography>}
                        />
                    )
                ):(
                    <TableSort data={[
                        {pro:"cliente",text:Strings.cliente},
                        {pro:"pagFecha",text:Strings.fecha},
                        {pro:"pagMonto",text:Strings.monto},
                        {pro:"pagMora",text:Strings.mora},
                        {pro:"pagDescuento",text:Strings.descuento},
                        {pro:"pagAnulado",text:Strings.totalPagado}
                    ]} onSort={tableSortProps} lessVh={205}>
                        <TableBody>
                            {!isEmpty && list.results.map(pag =>
                                <TableRow key={pag.pagId}
                                          className={pag.pagAnulado?classes.pagAnulado:pag.pagTipo === 4?classes.pagAjuste:""}
                                          onClick={()=> history.push(history.location.pathname,{recibo:pag})}>

                                    <TableCell>{pag.cliente}</TableCell>
                                    <TableCell>{formatDate(pag.pagFecha)}</TableCell>
                                    <TableCell>{formatPeso(pag.pagMonto)}</TableCell>
                                    <TableCell>{formatPeso(pag.pagMora)}</TableCell>
                                    <TableCell>{formatPeso(pag.pagDescuento)}</TableCell>
                                    <TableCell>{formatPeso(pag.pagTotal)}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        {/*{!isEmpty &&*/}
                        {/*<TableFooter>*/}
                        {/*    <TableRow>*/}
                        {/*        <TableCell colSpan={5}><Typography align={"right"}>Total:</Typography> </TableCell>*/}
                        {/*        <TableCell>{formatPeso(list.footerTotals.pagCapital)}</TableCell>*/}
                        {/*        <TableCell>{formatPeso(list.footerTotals.pagInteres)}</TableCell>*/}
                        {/*        <TableCell>{formatPeso(list.footerTotals.pagMora)}</TableCell>*/}
                        {/*        <TableCell>{formatPeso(list.footerTotals.pagDescuento)}</TableCell>*/}
                        {/*        <TableCell/>*/}
                        {/*        <TableCell>{formatPeso(list.footerTotals.pagTotal)}</TableCell>*/}
                        {/*    </TableRow>*/}
                        {/*</TableFooter>*/}
                        {/*}*/}
                    </TableSort>

                )}

                <Pagination
                    empty={Boolean(isEmpty && !isFetching)}
                    count={list.totalRecordCount || 0}
                    rowsPerPage={filter.pageSize}
                    page={list.pageNo -1}
                    onChangePage={(e,page)=>handleFilter({target:{name:"page",value:page+1}})}
                    onChangeRowsPerPage={handleFilter}
                />
            </section>
            <ReciboPago toInmIcon/>
        </>
    );
}
const mapStateToProps = (state) => ({
    pagos: state.pagos,
    viewTableList: state.theme.viewTableList
})
export default connect(mapStateToProps, { mostrarPagos})(PagosRealizados);