import * as React from 'react';
import { connect } from 'react-redux';
import {TextField, makeStyles, Grid, Button, CircularProgress,} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import {WhatsApp, YouTube,Email} from "@material-ui/icons";
import {useState} from "react";
import Strings from "../../assets/strings";
//import TopBarDialog from "../../components/topBar/TopBarDialog";
import {API} from "../../services";
//import {formatPeso} from "../../helpers";
//import {getTokenInfo} from "../../services/auth";

const useStyles = makeStyles(theme => ({
    root:{
        padding:theme.spacing(2)
    },
    contForm:{
        borderRight: "1px solid #dedddd",
        [theme.breakpoints.down('sm')]: {
            borderRight: "none",
            borderBottom: "1px solid #dedddd",
        },
    },
    head:{
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        textAlign:"center",
        boxShadow: "0px 2px 5px rgb(0 0 0 / 10%) inset",
        "& h2":{
            margin:0,
            marginBottom: 4,
            paddingTop:theme.spacing(2)
        },
        "& p":{
            margin: 0,
            paddingBottom:theme.spacing(2)
        }
    },
    links:{
        "& svg":{
          marginRight:5,
        },
        "& a":{
            marginBottom:8,
            color: theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
        }
    },
    table:{
        "& tr":{
            height: 30,
            "& .tick":{
                color: "#2CA01C",
            },
            "& .text-danger": {
                color: "#dc3545",
            }
        },
        "& tbody th":{
            textAlign: "left",
            fontWeight: "normal",
            paddingLeft: 10,
        },
        "& td":{
            textAlign: "center",
        }
    }
}));

function Index({user}) {
    const classes = useStyles();
    const [state, setState] = useState({uscConcepto:"",uscContacto:"",uscComentario:""});
  //  const [openDGPlan, setOpenDGPlan] = useState(false);
    const [msjSent, setMsjSent] = useState(false);
    const [error, setError] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleChange = (e)=>{
        const {name,value} = e.target
        return setState({...state,[name]: value});
    }
    // const payfor = (plan)=>{
    //    document.getElementById("selectPlan").value = plan;
    //    document.getElementById("paypalForm").submit();
    // }
    const submit = (e)=>{
        e.preventDefault();
        setLoading(true);
        setError(false);
        API.post("ayuda",state).then(()=>{
            setMsjSent(true);
        }).catch(()=>{
            setError(true)
        }).finally(()=>{
            setLoading(false);
        })
    }

   // const {UsuPlan} = getTokenInfo();
    //let freePlan =  Boolean(parseInt(UsuPlan) === TIPOPLAN.gratis);
    return (
        <>
            <div className={classes.head}>
                <h2>Ayuda / FeedBack</h2>
                {/*{freePlan?(*/}
                {/*        <p>¡Actualmente tienes una cuenta de prueba, escríbenos por WhatsApp para más información!</p>*/}
                {/*    ):(*/}
                {/*        <p>Tomamos muy en serio las necesidades de nuestro cliente, danos tu opinión y ayúdanos a mejorar.</p>*/}
                {/*    )*/}
                {/*}*/}
                <p>Tomamos muy en serio las necesidades de nuestro cliente, danos tu opinión y ayúdanos a mejorar.</p>
            </div>
            <section className={classes.root}>
              <div>
                      <Grid container spacing={3}>
                          <Grid item xs={12} md={6} className={classes.contForm}>
                              <p>Formulario de contacto:</p>
                              {!!error && <Alert onClose={()=>setError(false)} style={{marginBottom:16}} severity={"error"}>
                                  Se ha prodicido un error, intentelo mas tarde.</Alert>}
                              {!!msjSent?(
                                  <Alert severity="success">
                                      Mensaje enviado, Gracias.
                                  </Alert>
                              ):(
                                  <form onSubmit={submit}>
                                      <Grid container spacing={3}>
                                          <Grid item xs={12} sm={6}>
                                              <TextField fullWidth
                                                         onChange={handleChange}
                                                         name="uscConcepto"
                                                         required
                                                         value={state.uscConcepto}
                                                         label={Strings.concepto}
                                              />
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                              <TextField fullWidth
                                                         label={"Tel. / E-mail"}
                                                         value={state.uscContacto}
                                                         onChange={handleChange}
                                                         name={"uscContacto"}
                                                         required
                                              />
                                          </Grid>

                                          <Grid item xs={12}>
                                              <TextField
                                                  fullWidth
                                                  label={Strings.comentario}
                                                  multiline
                                                  rows={4}
                                                  value={state.uscComentario}
                                                  onChange={handleChange}
                                                  name={"uscComentario"}
                                                  required
                                              />
                                          </Grid>
                                      </Grid>
                                      <Button type={"submit"} disabled={loading} color={"primary"}>
                                          {loading? (
                                              <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                          ):(
                                              <span>{Strings.enviar}</span>
                                          )}
                                      </Button>
                                      {/*<Button type={"button"} onClick={()=>setOpenDGPlan(true)}>{Strings.ver} {Strings.precios}</Button>*/}
                                  </form>
                              )}
                          </Grid>
                          {/*{!freePlan &&*/}
                          {/*<Grid item xs={12} md={6} className={classes.contForm}>*/}
                          {/*    <p>Formulario de contacto:</p>*/}
                          {/*    {!!error && <Alert onClose={()=>setError(false)} style={{marginBottom:16}} severity={"error"}>*/}
                          {/*        Se ha prodicido un error, intentelo mas tarde.</Alert>}*/}
                          {/*    {!!msjSent?(*/}
                          {/*        <Alert severity="success">*/}
                          {/*            Mensaje enviado, Gracias.*/}
                          {/*        </Alert>*/}
                          {/*    ):(*/}
                          {/*        <form onSubmit={submit}>*/}
                          {/*            <Grid container spacing={3}>*/}
                          {/*                <Grid item xs={12} sm={6}>*/}
                          {/*                    <TextField fullWidth*/}
                          {/*                               onChange={handleChange}*/}
                          {/*                               name="uscConcepto"*/}
                          {/*                               required*/}
                          {/*                               value={state.uscConcepto}*/}
                          {/*                               label={Strings.concepto}*/}
                          {/*                    />*/}
                          {/*                </Grid>*/}
                          {/*                <Grid item xs={12} sm={6}>*/}
                          {/*                    <TextField fullWidth*/}
                          {/*                               label={"Tel. / E-mail"}*/}
                          {/*                               value={state.uscContacto}*/}
                          {/*                               onChange={handleChange}*/}
                          {/*                               name={"uscContacto"}*/}
                          {/*                               required*/}
                          {/*                    />*/}
                          {/*                </Grid>*/}

                          {/*                <Grid item xs={12}>*/}
                          {/*                    <TextField*/}
                          {/*                        fullWidth*/}
                          {/*                        label={Strings.comentario}*/}
                          {/*                        multiline*/}
                          {/*                        rows={4}*/}
                          {/*                        value={state.uscComentario}*/}
                          {/*                        onChange={handleChange}*/}
                          {/*                        name={"uscComentario"}*/}
                          {/*                        required*/}
                          {/*                    />*/}
                          {/*                </Grid>*/}
                          {/*            </Grid>*/}
                          {/*            <Button type={"submit"} disabled={loading} color={"primary"}>*/}
                          {/*                {loading? (*/}
                          {/*                    <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>*/}
                          {/*                ):(*/}
                          {/*                    <span>{Strings.enviar}</span>*/}
                          {/*                )}*/}
                          {/*            </Button>*/}
                          {/*            /!*<Button type={"button"} onClick={()=>setOpenDGPlan(true)}>{Strings.ver} {Strings.precios}</Button>*!/*/}
                          {/*        </form>*/}
                          {/*    )}*/}
                          {/*</Grid>*/}
                          {/*}*/}
                          <Grid item xs={12} md={6}>
                              <div className={classes.links}>
                                  <Grid container spacing={3}>
                                      <Grid item xs={6} >
                                          <p>Enlaces de interés</p>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"https://www.youtube.com/channel/UCb1PHnAm3IZs55qQU9pVAcA"}
                                          >
                                              <YouTube />
                                              Video Tutoriales
                                          </a>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={`https://wa.me/18498640680?text=${Strings.hola}, soy ${user.usuNombres} ${user.usuApellidos} mi usuario es ${user.usuInicioSesion}. `}
                                          >
                                              <WhatsApp />
                                              Chat vía WhatApp
                                          </a>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"mailto:segura12s@gmail.com,deibisoto12@gmail.com"}
                                          >
                                              <Email />
                                              E-mail
                                          </a>
                                          {/*<a href={"/"} onClick={(e)=>{setOpenDGPlan(true); e.preventDefault()}}>*/}
                                          {/*      <AccountBalanceWalletOutlined/>*/}
                                          {/*    {Strings.ver} {Strings.precios}</a>*/}

                                      </Grid>
                                      <Grid item xs={6} >

                                          <p>Disponible desde:</p>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"https://app.gestinmueble.com/"}
                                          >
                                              Web
                                          </a>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"https://play.google.com/store/apps/details?id=com.dsegura.gestinmueble"}
                                          >
                                              Google play / Android
                                          </a>

                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"https://gestinmueble.com/"}
                                          >
                                              Más información...
                                          </a>
                                      </Grid>
                                  </Grid>
                              </div>
                          </Grid>
                      </Grid>

              </div>

            </section>

            {/*<Dialog open={openDGPlan} aria-labelledby="form-dialog-title" onClose={()=>setOpenDGPlan(false)} fullWidth maxWidth={"md"}>*/}
            {/*    <TopBarDialog title={Strings.precios} onClose={()=>setOpenDGPlan(false)} nofullScreen/>*/}

            {/*    <DialogContent>*/}
            {/*        <Table className={classes.table}>*/}
            {/*            <thead>*/}
            {/*            <TableRow>*/}
            {/*                <td></td>*/}
            {/*                <th>DEMO</th>*/}
            {/*                <th>PERSONAL</th>*/}
            {/*                <th>BASICO</th>*/}
            {/*                <th>PROFESIONAL</th>*/}
            {/*                <th>AVANZADO</th>*/}
            {/*            </TableRow>*/}
            {/*            </thead>*/}
            {/*            <tbody>*/}
            {/*                <TableRow className="price">*/}
            {/*                    <td></td>*/}
            {/*                    <td>{formatPeso(0)}</td>*/}
            {/*                    {user.genLocalidad === "DO"?(<>*/}
            {/*                        <td>{formatPeso(500)} <span>/ {Strings.mes}</span></td>*/}
            {/*                        <td>{formatPeso(1000)} <span>/ {Strings.mes}</span></td>*/}
            {/*                        <td>{formatPeso(2000)} <span>/ {Strings.mes}</span></td>*/}
            {/*                        <td>{formatPeso(3000)} <span>/ {Strings.mes}</span></td>*/}
            {/*                    </>):(<>*/}
            {/*                        <td>$10 <span>USD/{Strings.mes}</span></td>*/}
            {/*                        <td>$20 <span>USD/{Strings.mes}</span></td>*/}
            {/*                        <td>$40 <span>USD/{Strings.mes}</span></td>*/}
            {/*                        <td>$60 <span>USD/{Strings.mes}</span></td>*/}
            {/*                    </>)}*/}

            {/*                </TableRow>*/}
            {/*                <TableRow>*/}
            {/*                    <th scope="row">Agentes ó Colaboradores</th>*/}
            {/*                    <td className="text-danger">✕</td>*/}
            {/*                    <td className="text-danger">✕</td>*/}
            {/*                    <td>1</td>*/}
            {/*                    <td>5</td>*/}
            {/*                    <td>10</td>*/}
            {/*                </TableRow>*/}
            {/*                <TableRow>*/}
            {/*                    <th scope="row">Clientes</th>*/}
            {/*                    <td>Ilimitado</td>*/}
            {/*                    <td>Ilimitado</td>*/}
            {/*                    <td>Ilimitado</td>*/}
            {/*                    <td>Ilimitado</td>*/}
            {/*                    <td>Ilimitado</td>*/}
            {/*                </TableRow>*/}
            {/*                <TableRow style={{background: "rgb(243, 243, 243)"}}>*/}
            {/*                    <th scope="row">Inmuebles activos</th>*/}
            {/*                    <td>3</td>*/}
            {/*                    <td>10</td>*/}
            {/*                    <td>20</td>*/}
            {/*                    <td>50</td>*/}
            {/*                    <td>150</td>*/}
            {/*                </TableRow>*/}
            {/*                <TableRow>*/}
            {/*                    <th scope="row">Copia de Seguridad</th>*/}
            {/*                    <td className="text-danger">✕</td>*/}
            {/*                    <td className="text-danger">✕</td>*/}
            {/*                    <td>Ilimitado</td>*/}
            {/*                    <td>Ilimitado</td>*/}
            {/*                    <td>Ilimitado</td>*/}
            {/*                </TableRow>*/}
            {/*            </tbody>*/}
            {/*            {user.genLocalidad !== "DO" &&*/}
            {/*                <tfoot>*/}
            {/*                    <TableRow>*/}
            {/*                        <td colSpan={2}/>*/}
            {/*                        <td><Button onClick={()=>payfor("Plan Personal")}>Seleccionar</Button></td>*/}
            {/*                        <td><Button onClick={()=>payfor("Plan Basico")}>Seleccionar</Button></td>*/}
            {/*                        <td><Button onClick={()=>payfor("Plan Profesional")}>Seleccionar</Button></td>*/}
            {/*                        <td><Button onClick={()=>payfor("Plan Avanzado")}>Seleccionar</Button></td>*/}
            {/*                    </TableRow>*/}
            {/*                </tfoot>*/}
            {/*            }*/}

            {/*        </Table>*/}
            {/*        {user.genLocalidad === "DO"?(<>*/}
            {/*            <div>*/}
            {/*                <hr/>*/}
            {/*                <p>Para adquirir uno de nuestros planes, debes realizar el pago a una de nuestras cuentas,*/}
            {/*                    luego enviarnos un comprobante junto a tu nombre de usuario, inmediatamente suscribimos*/}
            {/*                    su cuenta al plan deseado!.</p>*/}
            {/*                <Grid container spacing={3}>*/}
            {/*                    <Grid item xs={12} sm={6}>*/}
            {/*                        <b>Cuenta Banco Popular:</b><br />*/}
            {/*                        =======================<br />*/}
            {/*                        768453581 - Delmirio E. Segura Soto*/}
            {/*                    </Grid>*/}
            {/*                    <Grid item xs={12} sm={6}>*/}
            {/*                        <b>Cuenta Banreservas:</b><br />*/}
            {/*                        =======================<br />*/}
            {/*                        9601258849 - Deibis Segura Soto*/}
            {/*                    </Grid>*/}
            {/*                </Grid>*/}
            {/*                <br/>*/}
            {/*                <br/>*/}
            {/*            </div>*/}
            {/*        </>):(<>*/}
            {/*            <form action="https://www.paypal.com/cgi-bin/webscr" style={{display:"none"}}*/}
            {/*                  method="post" target="_blank" id="paypalForm">*/}
            {/*                <input type="hidden" name="cmd" value="_s-xclick"/>*/}
            {/*                <input type="hidden" name="hosted_button_id" value="ZBUCUKGJK9UJL"/>*/}


            {/*                <div className="form-group">*/}
            {/*                    <label><input type="hidden" name="on1" value="Usuario"/>Usuario:</label>*/}
            {/*                    <input className="form-control" type="text" name="os1" value={user.usuInicioSesion} readOnly/>*/}
            {/*                </div>*/}
            {/*                <div className="form-group">*/}
            {/*                    <label><input type="hidden" name="on0" value="Opciones de pago"/>Opciones de pago:</label>*/}
            {/*                    <select className="form-control" name="os0" id="selectPlan">*/}
            {/*                        <option value="Plan Personal">Plan*/}
            {/*                            Personal : $10.00 USD - mensual*/}
            {/*                        </option>*/}
            {/*                        <option value="Plan Basico">Plan Básico :*/}
            {/*                            $20.00 USD - mensual*/}
            {/*                        </option>*/}
            {/*                        <option value="Plan Profesional">Plan*/}
            {/*                            Profesional : $40.00 USD - mensual*/}
            {/*                        </option>*/}
            {/*                        <option value="Plan Avanzado">Plan*/}
            {/*                            Avanzado : $60.00 USD - mensual*/}
            {/*                        </option>*/}
            {/*                    </select>*/}
            {/*                </div>*/}
            {/*                <input type="hidden" name="currency_code" value="USD"/>*/}
            {/*                <div className="text-center">*/}
            {/*                    <input type="image"*/}
            {/*                           src="https://www.paypalobjects.com/es_ES/ES/i/btn/btn_subscribeCC_LG.gif"*/}
            {/*                           border="0" name="submit"*/}
            {/*                           alt="PayPal, la forma rápida y segura de pagar en Internet."/>*/}
            {/*                    <img alt="" border="0"*/}
            {/*                         src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif"*/}
            {/*                         width="1" height="1"/>*/}
            {/*                </div>*/}
            {/*            </form>*/}
            {/*        </>)}*/}
            {/*    </DialogContent>*/}
            {/*</Dialog>*/}
        </>
    );
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})
export default connect(mapStateToProps)(Index);

