import { paisTypes, _FULFILLED, _PENDING, _REJECTED } from '../actions/types';
const initialState = {
    list: [],
    isFetching: false,
}

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case paisTypes.MOSTRAR_PAISES + _PENDING: {
            return {
                ...state,
                isFetching: true,
            }
        }
        case paisTypes.MOSTRAR_PAISES + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
            }
        }
        case paisTypes.MOSTRAR_PAISES + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        case paisTypes.SET_PAISES: {
            return {
                ...state,
                list: action.payload
            }
        }
        default:
            return state;
    }
}