import { mensajeTypes } from './types';
import { getMensajes, msjMarkAsRead } from '../../services';


export function mostrarMensajes(obj) {
    return dispatch => {
        dispatch({
            type: mensajeTypes.MOSTRAR_MENSAJES,
            payload: getMensajes(obj)
        })
    }
}
export function marcarMsjLeido(id) {
    return dispatch => {
        dispatch({
            type: mensajeTypes.MARCAR_MENSAJE_LEIDO,
            payload: msjMarkAsRead(id)
        })
    }
}
export function mostrarMensajesSinoEsta() {
    return (dispatch, getState) => {
        const mensajes = getState().mensajes;
        if (Object.keys(mensajes.list).length || mensajes.isFetching) return false;
        dispatch({
            type: mensajeTypes.MOSTRAR_MENSAJES,
            payload: getMensajes()
        })
    }
}