export const PERSMISOS = {

    // Inicio:1,
    //
    // clientes:10,
    // clientesCrear:11,
    // clientesActualizar:12,
    // clientesEliminar:13,
    //
    // inmuebles:20,
    // inmueblesCrear:21,
    // inmueblesActualizar:22,
    // inmueblesEliminar:23,
    // inmueblesAjustes:24,
    // inmueblesCrearNotas:25,
    // inmueblesEliminarNotas:26,
    //
    //
    // pagos:30,
    // pagosCrear:31,
    // pagosEliminar:32,
    // pagosCambiarFecha:33,
    // pagosCambiarCaja:34,
    // pagosModigicarMora:35,
    // pagosDescuento:36,
    //
    // cajas:40,
    // cajasCrear:41,
    // cajasActualizar:42,
    // cajasEliminar:43,
    // cajasRealizarCierres:44,
    // cajasTransferencias:45,
    //
    // gastos:50,
    // gastosCrear:51,
    // gastosEliminar:52,
    //
    // solicitudes:60,
    // solicitudesAprobar:61,
    // solicitudesRechazar:62,
    //
    // grupos:70,
    // gruposCrear:71,
    // gruposActualizar:72,
    // gruposEliminar:73,
    //



    dashboard: 1,
    mapa:2,

    clientes:10,
    clientesCrear:11,
    clientesActualizar:12,
    clientesEliminar:13,

    inmuebles: 20,
    inmueblesCrear:21,
    inmueblesActualizar:22,
    inmueblesEliminar:23,
    inmueblesCambiarEstado:24,

    contratos: 30,
    contratosCrear:31,
    contratosActualizar:32,
    contratosEliminar:34,
    contratosCrearNotas:35,
    contratosEliminarNotas:36,

    pagos: 40,
    pagosCrear:41,
    pagosEliminar:42,
    pagosCambiarFecha:43,
    pagosModificarMora:44,
    pagosDescuento:45,

    gastos: 50,
    gastosCrear:51,
    gastosEliminar:52,

    caracteristicas: 60,
    caracteristicasCrear:61,
    caracteristicasActualizar:62,
    caracteristicasEliminar:63,

    mensajes: 70,
    mensajesMarcarLeida:71,
    mensajesEliminar:72,

    grupos: 80,
    gruposCrear:81,
    gruposActualizar:82,
    gruposEliminar:83,

    servicios: 90,
    serviciosCrear:91,
    serviciosActualizar:92,
    serviciosEliminar:93
    
    
};
export const TIPOUSUARIO = {
    titular: 1,
    agente: 2,
    admin: 99,
}
export const TIPOPLAN = {
    gratis: 1,
    basico: 2,
    profesional: 3,
    avanzado: 4,

}
export const TIPOPAGO ={
    CUOTA_COMPLETA: 1,
    PAGO_PARCIAL: 2,
    ABONO_CAPITAL: 3,
    AUSTE_CAPITAL: 4,
    SOLO_INTERES: 5,
    SAlDAR:6
};
export const mapApiOptions = {
    id: 'google-map-script',
    libraries:["places"],
    googleMapsApiKey: "AIzaSyDpi0iwVulr11eYWuk66OgcW4w-tq4sAh4",
}