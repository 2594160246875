import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import {mostrarDashBoardData} from "../../redux/actions/dashBoardActions";
import CuentasXcobrar from '../../components/homeWidget/CxC';
import IngresosChart from '../../components/homeWidget/IngresosChart';
import HomeCounter from '../../components/homeWidget/HomeCounter';
//import ModalShareLink from "../../componentsOld/modalShareInm";
import AppBar from "../../components/appBar"
import {Grid, makeStyles, Hidden, Backdrop, IconButton, CircularProgress, Button} from '@material-ui/core';
import {Alert, SpeedDial, SpeedDialAction} from "@material-ui/lab";
import Strings from '../../assets/strings';
import {Add,HomeWork, PeopleAltOutlined,Refresh} from "@material-ui/icons"
import {hasPermissionTo, PERSMISOS} from "../../helpers";

const useStyles = makeStyles(theme => ({
    root:{
        paddingBottom: 0,
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2)
        }
    },
    speedDial:{
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    sDAction:{
        "& .MuiSpeedDialAction-staticTooltipLabel":{
            color: theme.palette.secondary.main,
            border: "1px solid " + theme.palette.secondary.main,
            boxShadow: "none",
            padding: "0px 10px",
            whiteSpace: "nowrap",
            fontSize: ".9rem",
        },
        "& svg":{
            color:theme.palette.primary.main,
        }

    },
    drawerTop:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        height:50,
        paddingLeft:theme.spacing(2),
        background:theme.palette.primary.main,
        color:theme.palette.common.white,
    },
    listRoot:{
        minWidth:255,
    },
    alert:{
        [theme.breakpoints.down('sm')]: {
            "& .MuiAlert-icon":{
                display: "none",
            },
            flexDirection: "column",
        }
    }
}));
function Home({mostrarDashBoardData,isFetching,userName}) {
    const classes = useStyles();
    const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
    useEffect(()=>{
        mostrarDashBoardData()
    },[mostrarDashBoardData]);

    const contactar =() =>{
        window.open("https://api.whatsapp.com/send/?phone=18498641680&text=Hola, desde gestinmueble, mi usuario es "+userName,"_Blank")
    }
    return (
        <>
            <AppBar title="Inicio">
                <IconButton onClick={mostrarDashBoardData} disabled={isFetching}>{isFetching?<CircularProgress size={20} />:<Refresh/>}</IconButton>

                {/*<ModalShareLink link={"https://gestinmueble.com/"+props.userName} btnText="Mi web pública"/>*/}
            </AppBar>
            <div className={"contentInner " + classes.root}>
                {/*{parseInt(UsuPlan) === TIPOPLAN.gratis &&*/}
                    <Alert className={classes.alert} severity="warning"  variant={"outlined"} action={
                        <div>
                            <Button color="inherit" size="small" component={"a"} href={"https://www.youtube.com/channel/UCb1PHnAm3IZs55qQU9pVAcA"} target={"_blank"}>
                                Videos
                            </Button>
                            <Button color="inherit" size="small" onClick={contactar}>
                                {Strings.contacto}
                            </Button>
                        </div>
                    }>
                        {Strings.verVideosMsj}
                    </Alert>
                    <br/>
                {/*}*/}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <HomeCounter />
                    </Grid>
                    <Grid item xs={12} lg={5}>
                        {hasPermissionTo(PERSMISOS.prestamos) &&
                            <CuentasXcobrar/>
                        }
                    </Grid>
                    <Grid item xs={12} lg={7} className={"d-print-none"}>
                        <IngresosChart/>
                    </Grid>
                </Grid>
            </div>
            <Hidden mdUp>
                <Backdrop open={openSpeedDial} style={{opacity: .4, zIndex: 2}} />
            </Hidden>
            <SpeedDial
                ariaLabel="Short Cut"
                className={classes.speedDial}
                icon={<Add/>}
                onClose={()=>setOpenSpeedDial(false)}
                onOpen={()=>setOpenSpeedDial(true)}
                open={openSpeedDial}
            >
                {hasPermissionTo(PERSMISOS.clientesCrear) &&
                    <SpeedDialAction
                        classes={{staticTooltip:classes.sDAction}}
                        icon={<PeopleAltOutlined/>}
                        tooltipTitle={Strings.agregar +" cliente"}
                        tooltipOpen
                        FabProps={{
                            component: Link,
                            to: "/CrearCliente"
                        }}
                    />
                }
                {hasPermissionTo(PERSMISOS.inmueblesCrear) &&
                    <SpeedDialAction
                        classes={{staticTooltip: classes.sDAction}}
                        icon={<HomeWork/>}
                        tooltipTitle={Strings.agregar + " Immbueble"}
                        tooltipOpen
                        FabProps={{
                            component: Link,
                            to: "/CrearInmueble"
                        }}
                    />
                }
            </SpeedDial>
        </>
    );
}

const mapStateToProps = (state) => ({
    dashBoard: state.dashBoard,
    isFetching: state.dashBoard.isFetching,
    userName: state.auth.user.UserName

});
export default connect(mapStateToProps, { mostrarDashBoardData})(Home);
