import React from 'react';
import {makeStyles, Grid} from "@material-ui/core";
import CounterItem from "./CounterItem";
import {DescriptionOutlined,TrendingDown,HomeOutlined, TrendingUp} from "@material-ui/icons";
import {formatPeso} from "../../helpers";
import {connect} from "react-redux";
import Strings from "../../assets/strings";
import moment from "moment";
import HtmlTooltip from "../HtmlTooltip";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('xs')]: {
         //   padding: theme.spacing(2)
        },
        "& a":{
            textDecoration: "none",
            color: "inherit",
            display:"block"
        }
    }
}));

function HomeCounter({dashBoard,usuInicioSesion}) {

    const classes = useStyles();
    return (
        <Grid container spacing={2} className={"d-print-none " + classes.root}>
            <Grid item xs={6} md={3}>
                <CounterItem title={"Contratos activos"}  subTitle={dashBoard.contratos} Icon={DescriptionOutlined} color={"#796AEE"}/>
            </Grid>
            <Grid item xs={6} md={3}>
                <HtmlTooltip title={Strings.totalInmPublicoMjs}>
                    <a href={"https://gestinmueble.com/"+usuInicioSesion} target={"_blank"} rel="noreferrer" >
                <CounterItem title={"Inmuebles públicos"} subTitle={dashBoard.inmueblesPublico} Icon={HomeOutlined} color={"#54e69d"}/>
                    </a>
                </HtmlTooltip>
            </Grid>

            <Grid item xs={6} md={3}>
                <CounterItem title={Strings.ingresos+" "+moment(new Date()).format('MMMM')}
                             subTitle={formatPeso(dashBoard.ingresos)} Icon={TrendingUp} color={"#2196f3"}/>
            </Grid>

            <Grid item xs={6} md={3}>
                <CounterItem title={Strings.gastos+" "+moment(new Date()).format('MMMM')}
                             subTitle={formatPeso(dashBoard.gastos)} Icon={TrendingDown} color={"#d9534f"}/>
            </Grid>

        </Grid>
    )
}

const mapStateToProps = (state) => ({
    dashBoard: state.dashBoard,
    usuInicioSesion:state.auth?.user?.usuInicioSesion
});
export default connect(mapStateToProps)(HomeCounter);

