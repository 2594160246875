import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import {connect} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import { mostrarContratoById } from '../../redux/actions/contratoActions';
import { mostrarClienteById} from "../../redux/actions/clientesActions";

import {formatPeso, formatDate, alertNote, hasPermissionTo, PERSMISOS, openNewWin} from '../../helpers';
import {IconButton, Typography, Grid, MenuItem, Menu, Button, Paper, Hidden , useMediaQuery} from '@material-ui/core';
import {MoreVert, SmsOutlined,MoreHoriz} from '@material-ui/icons';

import ListPagos from '../../components/listPagos'
import {AppBar, ButtonResponsive, LoadingProgress} from "../../components";
import HacerPago from './addPago'
import Strings from "../../assets/strings";
import NoImg from '../../assets/img/defaultAvatar.jpg';
import CancelDialog from "./CancelDialog";
import NotasContrato from "./NotasContrato";

const useStyles = makeStyles(theme => ({
    clientAvatar: {
        textAlign: "center",
        padding: 20,
        border: "1px solid #ddd",
        height: 104,
        [theme.breakpoints.down('sm')]: {
            textAlign: "left",
            padding: "0px 16px 8px",
            border: "none",
            background: theme.palette.primary.main,
            color:"white",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            height: "auto",
            position: "fixed",
            width: "100%",
            zIndex: 1,
        },
    },
    imgCont:{
        width: 80,
        height: 80,
        marginBottom:6,
        margin: "0 auto",
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            margin: "0",
            marginRight: theme.spacing(2),
            height: 45,
            width: 45,
            "& img":{
                width: 45,
                height: 45,
            }
        },

    },
    contentInner:{
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    estadoRating:{
        [theme.breakpoints.down('sm')]: {
            display:"flex",
        },

    },
    estado:{
        marginTop: 12,
        textAlign: "center",
        height: 17,
        padding: "7px 14px",
        background: "#eceeef",
        [theme.breakpoints.down('sm')]: {
            // marginTop: 0,
            // marginBottom:theme.spacing(1),
            display:"none",
        },
        "&.c":{
            background:"rgba(216, 67, 21, 0.13)",
            "& .estado":{
                color: "#d84315",
            }
        },
        "&.m":{
            background:"rgba(255, 111, 0, 0.05)",
            "& .estado":{
                color: "#ff6f00",
            }
        },
        "&.p":{
            background:"rgba(25, 118, 210, 0.07)",
            "& .estado":{
                color: "#1976d2",
            }
        }
    },

    imgCircle: {
        borderRadius: "50%",
        [theme.breakpoints.down('sm')]: {
            borderRadius: theme.shape.borderRadius,
        },
    },
    controls:{
        marginTop: 10,
        overflowX: 'auto',
        boxShadow: "none",
        borderRadius: 0,
        "& > div":{
            minWidth: 479,
        },
        "& a , button:not(:first-child)":{
            textTransform: "capitalize",
            fontWeight: "normal",
            fontSize: ".9rem",
        },
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
        }
    },

    table: {
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(3),
        },
        marginBottom: 35,
    },
    button: {
        marginLeft: theme.spacing(1),
    },
    info:{
        overflowX: "auto",
        overflowY: "hidden",

        padding: 10,
        [theme.breakpoints.up('md')]: {
            background:"#eceeef",
            padding: 20,
            boxShadow: "none",
            borderRadius: 0,
            "&:not(.all)":{
                height: 106,
            },
            "&>div":{
                minWidth: 729,
            }
        },
        position: "relative",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)+"px",
            margin: "53px 0 0 0px",
            borderRadius: "0",
            boxShadow: "none"
        },
    },
    btnMoreInfo:{
        position:"absolute",
        right:theme.spacing(2),
        cursor:"pointer",
    },
    moreInfo:{
        background:theme.palette.grey[100],
        "& span":{
            fontSize: 15,
        }
    },
    itemInfo:{
        ...theme.typography.subheading,
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            flexDirection: "column-reverse",
            padding: "5px 16px !important",
        },
        "& span:last-child":{
            background: "#f6f6f6",
            padding: "5px 13px",
            marginLeft: 10,
            [theme.breakpoints.down('sm')]: {
                background: "none",
                marginLeft: 0,
                padding: 0,
                fontSize: "0.9rem",
            },
        },
        "& span:first-child":{
            [theme.breakpoints.down('sm')]: {
                display: "block",
                fontSize: ".7rem",
            },
        }
    },
    cancelBox:{
        "& .title":{
            background: "#ffdede",
        },
        "& textarea":{
            width: "calc(100% - 7px)",
            border: "1px solid #e6e6e6",
            resize: "none",
        },
        "& .content":{
            padding: "10px 10px 0 10px",
        }
    },
    contGrid:{
        [theme.breakpoints.down('sm')]: {
            padding: "0px !important",
            margin: "0px !important",
        },
    },
    btnPagoMobile:{
        margin: theme.spacing(1),
    }
}));


function DetallesContrato({contrato,mostrarContratoById,mostrarClienteById,isFetching}) {

    const classes = useStyles();

    const {goBack,...history} = useHistory();
    const {state:hState} = useLocation();
    let { conId } = useParams();

    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));
    const [showAllInfo, setShowAllInfo] = useState(false);

    const [openMenu, setOpenMenu] = useState(null);

    useEffect(() => {
        if (conId) mostrarContratoById(conId);
    }, [mostrarContratoById, conId]);

    const pushUrl = (state)=>{
        if(openMenu)setOpenMenu(false);
        let pathname = history.location.pathname;

        history.push(pathname,state);
    };
    const showClientModal = cliId => {
        mostrarClienteById(cliId);
        pushUrl({cliId});
    }
    const exportar = ()=>{
        openNewWin("/reports/detallesContrato/"+conId);
    }
    return (
        <>
            <AppBar title={Strings.contrato}>
                <ButtonResponsive icon={<SmsOutlined/>} text={Strings.notas} onClick={()=> pushUrl({verNotas:contrato.conId})}/>
                <Hidden smUp>
                    <IconButton aria-haspopup="true" size={"small"} onClick={(e)=>setOpenMenu(e.currentTarget)}>
                        <MoreVert />
                    </IconButton>
                    <Menu id="long-menu" anchorEl={openMenu} keepMounted open={Boolean(openMenu)} onClose={()=>setOpenMenu(null)}>
                        {(!contrato.conAnulado && hasPermissionTo(PERSMISOS.contratosActualizar)) &&
                        <MenuItem onClick={()=> history.push("/editarContrato/"+contrato.conId)}>{Strings.editar}</MenuItem>
                        }
                        {(!contrato.conAnulado && hasPermissionTo(PERSMISOS.contratosEliminar)) &&
                        <MenuItem className={"text-red"} onClick={()=> pushUrl({modalCancel:contrato.conId})}>{Strings.anular} {Strings.contrato}</MenuItem>
                        }
                        <MenuItem onClick={()=>openNewWin("/reports/contrato/"+conId)}>{Strings.contrato}</MenuItem>
                        <MenuItem style={{display:"none"}} onClick={exportar}>{Strings.exportar}</MenuItem>
                    </Menu>
                </Hidden>
            </AppBar>
            <div className={"contentInner "+classes.contentInner}>
                {isFetching && <LoadingProgress vh/>}
                <Grid container spacing={smVewport?0:2}>
                    <Grid item xs={12} md={3}>
                        <div className={classes.clientAvatar} >
                            <div className={classes.imgCont}>
                                <img height="80" width="80" alt="Avatar" src={contrato.cliAvatar ? contrato.cliAvatar: NoImg} className={classes.imgCircle} />
                            </div>
                            <div>
                                <Typography  noWrap={true} color="inherit">
                                    <span>{contrato.cliNombre}</span>
                                </Typography>
                            </div>
                        </div>
                        <div className={`${classes.estado} ${contrato.conEstadoDescripcion?.charAt(0)}`}>
                            <span>{contrato.conCuotasPendiente>0 && contrato.conCuotasPendiente} {Strings[contrato.conEstadoDescripcion]} </span>
                        </div>

                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Paper className={(showAllInfo?"all":"")+" "+classes.info} onClick={()=>{if(!window.getSelection().toString()) setShowAllInfo(!showAllInfo);}}>
                            <Grid container spacing={3}>
                                {!showAllInfo && <MoreHoriz className={classes.btnMoreInfo}/>}
                                {showAllInfo &&
                                <Grid item xs={12} className={classes.moreInfo}>
                                    <Grid container spacing={3}>
                                        <Typography noWrap component={Grid} item xs={4}  className={classes.itemInfo}>
                                            <span>{Strings.fechaInicio}:</span>
                                            <span>{formatDate(contrato.conFecha)}</span>
                                        </Typography>
                                        {!!contrato.creadoPor &&
                                        <Typography noWrap component={Grid} item xs={4}  className={classes.itemInfo}>
                                            <span>{Strings.creadoPor}:</span>
                                            <span>{contrato.creadoPor}</span>
                                        </Typography>
                                        }
                                    </Grid>
                                </Grid>
                                }
                                <Grid item xs={4}  className={classes.itemInfo}>
                                    <span>{Strings.contrato}</span>
                                    <span>{contrato.conReferencia}</span>
                                </Grid>
                                <Grid item xs={4}  className={classes.itemInfo}>
                                    <span>Inmueble:</span>
                                    <span><Link to={"/Inmueble/" + contrato.inmId}> {contrato.inmReferencia}</Link></span>
                                </Grid>
                                <Grid item xs={4}  className={classes.itemInfo}>
                                    {contrato.conMontoPendiente < 0 ? (
                                        <span>{Strings.pendiente}:</span>
                                    ):(
                                        <span>Acumulado:</span>
                                    )
                                    }
                                    <span className={contrato.conMontoPendiente < 0 ? "text-danger":"" }>{formatPeso(Math.abs(contrato.conMontoPendiente))}</span>
                                </Grid>
                                <Grid item xs={4}  className={classes.itemInfo}>
                                    <span>Monto a pagar:</span>
                                    <span>{formatPeso(contrato.conMontoApagar)}</span>
                                </Grid>
                                <Grid item xs={4}  className={classes.itemInfo}>
                                    <span>{Strings.deposito}:</span>
                                    <span>{formatPeso(contrato.conMontoInicial)}</span>
                                </Grid>
                                <Grid item xs={4}  className={classes.itemInfo}>
                                    <span>Fecha Fin:</span>
                                    <span>{formatDate(contrato.conFechaVencimiento)}</span>
                                </Grid>

                                <Grid item xs={4}  className={classes.itemInfo}>
                                    <span>{Strings.proximo} {Strings.pago}:</span>
                                    <span>{formatDate(contrato.conFechaProximoPago)}</span>
                                </Grid>
                                <Grid item xs={4}  className={classes.itemInfo}>
                                    <span>{Strings.cicloPagos}:</span>
                                    <span>{contrato.conCiclosPagoDescripcion}</span>
                                </Grid>

                                <Grid item xs={4}  className={classes.itemInfo}>
                                    <span>Servicios:</span>
                                    <span>{contrato.servicios?.length+" items"}</span>
                                    {/*<span onClick={contrato.servicios.length ? this.viewServicios : () => false}>{servicios.length} Serv.</span>*/}
                                </Grid>

                            </Grid>
                        </Paper>
                        <Hidden only={"xs"}>
                            <Paper className={"d-print-none " +classes.controls}>
                                {hasPermissionTo(PERSMISOS.pagosCrear) &&
                                <Button size={"small"} onClick={() => pushUrl({modalPago: contrato.conId})}
                                        disabled={contrato.conAnulado}
                                        color={"primary"} variant={"contained"}>{Strings.agregar} {Strings.pago}</Button>
                                }
                                {!contrato.conAnulado && <>
                                    {hasPermissionTo(PERSMISOS.contratosActualizar) &&
                                    <Button size="small"  className={classes.button} onClick={()=>history.push("/editarContrato/"+contrato.conId)}> {Strings.editar}</Button>
                                    }
                                    {hasPermissionTo(PERSMISOS.contratosEliminar) &&
                                    <Button size="small" color="secondary" className={"text-red "+classes.button}
                                            onClick={()=> pushUrl({modalCancel:contrato.conId})}>{Strings.anular}</Button>
                                    }
                                </>}

                                <Button size="small" className={classes.button}
                                        onClick={()=>openNewWin("/reports/contrato/"+conId)}
                                >{Strings.contrato}</Button>
                                <Button style={{display:"none"}}  size="small" className={classes.button} onClick={exportar}>{Strings.exportar}</Button>

                            </Paper>
                        </Hidden>
                    </Grid>
                    {hasPermissionTo(PERSMISOS.pagosCrear) &&
                    <Hidden smUp>
                        <Button className={classes.btnPagoMobile} onClick={()=> pushUrl({modalPago:contrato.conId})}
                                disabled={contrato.conAnulado}
                                fullWidth color={"primary"} variant={"contained"}>{Strings.agregar} {Strings.pago}</Button>
                    </Hidden>
                    }
                </Grid>
                <div className={classes.table}>
                    <ListPagos pagos={contrato.pagos?contrato.pagos:[]} alertNote={alertNote}/>
                </div>
            </div>


            {!!hState?.modalPago && <HacerPago/>}
            {!!hState?.verNotas && <NotasContrato/>}
            {/*{!!hState?.modalContrato && <ContratoDialog conId={contrato.conId}/>}*/}
            {!!hState?.modalCancel && <CancelDialog />}
        </>
    );


}

const mapStateToProps = (state) => ({
    contrato: state.contratos.byId,
    isFetching: (state.contratos.isFetching || state.clientes.isFetching), //cliente se puedes ver desde aqui
});
export default connect(mapStateToProps, { mostrarContratoById,mostrarClienteById })(DetallesContrato);
