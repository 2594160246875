import { SET_CURRENT_USER,usuarioTypes,_PENDING, _REJECTED, _FULFILLED } from '../actions/types';
import {isEmpty} from '../../helpers';

const initialState = {
    isAuthenticated: false,

    user: {},
    isFetching: false,
    isPending: false,
    error: {},
}

export default function index(state = initialState, action={}) {
    switch(action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: {...state.user, ...action.payload}
            }
        //mostrar por id
        case usuarioTypes.MOSTRAR_PERFIL + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case usuarioTypes.MOSTRAR_PERFIL + _FULFILLED: {
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload.data),
                user: action.payload.data,
                isFetching: false,
                error: {}
            }
        }
        case usuarioTypes.MOSTRAR_PERFIL + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }

        //agregar  o editar
        case usuarioTypes.EDITAR_PERFIL + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case usuarioTypes.EDITAR_PERFIL + _FULFILLED: {
            const { data } = action.payload;
            return {
                ...state,
                user:data,
                isPending: false,
                error: {}
            }
        }
        case usuarioTypes.EDITAR_PERFIL + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        default:
            return state;
    }
}
// import { SET_CURRENT_USER, CHANGE_CURRENT_USER_INFO } from '../actions/types';
// import {isEmpty} from '../../services/helpers';
//
// const initialState = {
//     isAuthenticated: false,
//     user: {}
// }
//
// const index = function(state = initialState, action ) {
//     switch(action.type) {
//         case SET_CURRENT_USER:
//             return {
//                 ...state,
//                 isAuthenticated: !isEmpty(action.payload),
//                 user: action.payload
//             }
//         case CHANGE_CURRENT_USER_INFO:
//             return {
//                 ...state,
//                 user: { ...state.user, ...action.payload}
//             }
//         default:
//             return state;
//     }
// }
// export default index;