import React, { useEffect} from 'react';
import { connect } from 'react-redux';
import { mostrarConServicios, eliminarConServicio} from '../../redux/actions/serviciosContratoActions';
import AppBar from "../../components/appBar";
import Crear  from './crear';
import {
    ListItem,
    List,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Button,
} from "@material-ui/core";
import PlusFab from "../../components/PlusFab";
import {Close} from "@material-ui/icons";
import LoadingProgress from "../../components/LoadingProgress";
import {useHistory, useLocation} from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import {MostrarError} from "../../components/MostrarError";
import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {Empty} from "../../components";
import {ReactComponent as EmptySvg} from "../../assets/img/map.svg";

function ConServicios(props) {
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();

    const { mostrarConServicios } = props;
    useEffect(() => {
        mostrarConServicios();
    }, [mostrarConServicios]);

    const { servicios: { list, isFetching, error } } = props;
    const isEmpty = !isFetching && list.length === 0;

    return (
        <>
            <AppBar title={"Servicios de Contrato"}/>
            <section className="contentInner" style={!isEmpty ? {maxWidth:600}:{}}>
                {!urlState && <MostrarError errors={error}/>}
                {isFetching  && !list.length && <LoadingProgress vh/>}

                <List>
                    {!isEmpty && list.map(item =>
                        <ListItem role={undefined} button key={item.serId}
                                  onClick={()=>{
                                      if(!hasPermissionTo(PERSMISOS.serviciosActualizar) || item.registroDelSistema) return false;
                                      history.push(history.location.pathname,item)}}>
                            <ListItemText primary={item.serDescripcion} />
                            {hasPermissionTo(PERSMISOS.serviciosEliminar) && !item.registroDelSistema &&
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments" onClick={()=>history.push(history.location.pathname,{confirmDialog:item.serId})} >
                                    <Close className="text-red" fontSize={"small"}/>
                                </IconButton>
                            </ListItemSecondaryAction>
                            }
                        </ListItem>
                    )}
                </List>
                {isEmpty &&
                <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.conServicios}>
                    <p>{Strings.conServiciosHelpMsj}</p>
                </Empty>
                }


                {hasPermissionTo(PERSMISOS.serviciosCrear) &&
                <PlusFab component={Button} onClick={()=>history.push(history.location.pathname,{serId:""})} />
                }
                <Crear/>


                <ConfirmDialog onConfirm={()=>props.eliminarConServicio(urlState && urlState.confirmDialog)}/>

            </section>
        </>
    );

}

const mapStateToProps = (state) => ({
    servicios: state.servicios
});
export default connect(mapStateToProps, { mostrarConServicios, eliminarConServicio })(ConServicios);