import { usosMultiplesTypes } from './types';
import { getUsosMultiples, addUsosMultiples, editUsosMultiples, delUsosMultiples } from '../../services';
import history from "../../helpers/history";

export function mostrarUsosMultiples(usoGrupo) {
    return dispatch => {
        dispatch({
            type: usosMultiplesTypes.MOSTRAR_USOS_MULTIPLES,
            payload: getUsosMultiples(usoGrupo)
        })
    }
}
export function mostrarUsosMultiplesSinoEsta(usoGrupo) {
    return (dispatch, getState) => {
        const usosMultiples = getState().usosMultiples;


        if(Boolean(usosMultiples.list && usosMultiples.list[usoGrupo])) return false;

        dispatch({
            type: usosMultiplesTypes.MOSTRAR_USOS_MULTIPLES,
            payload: getUsosMultiples(usoGrupo)
        })
    }
}



export function eliminarUsoMultiple({usoGrupo, usoCodigo}) {
    return dispatch => {
        dispatch({
            type: usosMultiplesTypes.ELIMINAR_USO_MULTIPLE,
            payload: delUsosMultiples(usoGrupo, usoCodigo)
        })
    }
}
export function agregarEditarUsoMultiple(obj) {
    return (dispatch) => {
        dispatch({
            type: usosMultiplesTypes.AGREGAR_EDITAR_USO_MULTIPLE,
            payload: obj.usoFechaCreacion ? editUsosMultiples(obj) : addUsosMultiples(obj)
        }).then(history.goBack)
    }
}