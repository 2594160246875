import React from "react";
import "./style.scss";
import Box from "@material-ui/core/Box";
export default function SuccessMsj({msj="Guardado con éxito."}) {
    return(
        <>
            <div className="success-checkmark">
                <div className="check-icon">
                    <span className="icon-line line-tip"></span>
                    <span className="icon-line line-long"></span>
                    <div className="icon-circle"></div>
                    <div className="icon-fix"></div>
                </div>
            </div>
            <Box align={"center"} color="text.primary">{msj}</Box>
        </>
    )
}