import React, {useState} from 'react';
import {Button, Dialog, DialogContent, TextField, DialogActions, Box, Grid, CircularProgress} from "@material-ui/core";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import LoadingProgress from "../../components/LoadingProgress";
import {PostRegister} from "../../services";
import Strings from "../../assets/strings";
import {TextFieldPhone} from "../../components/CustomTextField";
import PlusFab from "../../components/PlusFab";
import {MostrarError} from "../../components";

export default function Crear() {
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [state, setState] = useState({
        usuInicioSesion: "",
        usuClave: "",
        usuNombres: "",
        usuApellidos: "",
        usuEmail: "",
        usuTelefono: "",
    });

    const handleChange = (e)=>{
        const {name, value} = e.target
        setState({...state, [name]: value});

        if(error) setError(null);
    }
    const submitForm = (e) =>{
        e.preventDefault();
        setLoading(true)
        PostRegister(state)
            .then(() =>{
                setOpen(false)
            })
            .catch(({response}) => {
                setError(response.data)
                setLoading(false)
            });
    }
    return (
        <>
            <PlusFab component={Button}  onClick={()=>setOpen(true)}/>
            <Dialog open={open} onClose={()=>setOpen(false)} maxWidth={"xs"}>
                <TopBarDialog title={Strings.agregar} onClose={()=>setOpen(false)} nofullScreen/>
                    <DialogContent>
                        <MostrarError errors={error}/>
                        {isLoading && <LoadingProgress />}
                        <form id="form" onSubmit={submitForm}>
                            <Box  color="text.secondary">{Strings.datosPersonales}</Box>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <TextField
                                        variant={"outlined"}
                                        label={Strings.primerNombre}
                                        required
                                        margin="dense"
                                        name="usuNombres"
                                        value={state.usuNombres}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant={"outlined"}
                                        label={Strings.apellidos}
                                        required
                                        margin="dense"
                                        name="usuApellidos"
                                        value={state.usuApellidos}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant={"outlined"}
                                        label={Strings.email}
                                        type="mail"
                                        required
                                        margin="dense"
                                        name="usuEmail"
                                        value={state.usuEmail}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextFieldPhone
                                        flag
                                        variant={"outlined"}
                                        label={Strings.telefono}
                                        type="mail"
                                        required
                                        margin="dense"
                                        name="usuTelefono"
                                        value={state.usuTelefono}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Box color="text.secondary" width={"100%"}>{Strings.datosParaIniciarSesion}</Box>
                                <Grid item xs={6}>
                                    <TextField
                                        variant={"outlined"}
                                        label={Strings.nombreDeUsuario}
                                        required
                                        margin="dense"
                                        name="usuInicioSesion"
                                        value={state.usuInicioSesion}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        variant={"outlined"}
                                        label={Strings.contrasena}
                                        required
                                        margin="dense"
                                        name="usuClave"
                                        value={state.usuClave}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" form="form" color="primary" disabled={isLoading}>
                            {isLoading? (
                                <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                            ):(
                                <>{Strings.guardar}</>
                            )}
                        </Button>
                    </DialogActions>
            </Dialog>
        </>
    );

}
