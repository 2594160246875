import { estadoTypes, _FULFILLED, _PENDING, _REJECTED } from '../actions/types';
const initialState = {
    list: [],
    isFetching: false,
    error: null,
}
 
const index = (state = initialState, action={}) => {

    switch (action.type) {
        case estadoTypes.MOSTRAR_ESTADOS + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case estadoTypes.MOSTRAR_ESTADOS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
                error: {}
            }
        }
        case estadoTypes.MOSTRAR_ESTADOS + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
    default:
      return state;
  }
}

export default index ;