import React, {useState, useEffect} from 'react';
import {agregarEditarContrato, mostrarContratoById} from "../../redux/actions/contratoActions";
import {Button, Grid, CircularProgress, makeStyles, TextField} from "@material-ui/core";
import {useParams} from "react-router-dom";
import {formatDate} from "../../helpers";
import {useDispatch, useSelector} from "react-redux";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
import {AppBar, MostrarError} from "../../components";
import SelectUsoMultiples from "../../components/selects/SelectUsoMultiples";
import SelectConServicios from "../../components/selects/SelectConServicios";

import {TextFieldCurrency,DateInput} from "../../components/CustomTextField";
import SearchInmueble from "../../components/selects/searchSelects/SearchInmueble";
import SearchCliente from "../../components/selects/searchSelects/SearchCliente";
import SelectEnum from "../../components/selects/SelectEnum";
const useStyles = makeStyles(theme => ({
    root:{
        padding:"20px",

    },
}));

const initialState = {
    conTipo: "1",
    inmId: "",
    inmReferencia:"",
    conReferencia:"",
    cliId: "",
    cliNombre:"",
    conFecha:formatDate(new Date(),'YYYY-MM-DD'),
    conFechaProximoPago:"",
    conRenovacion: "",
    conMontoInicial: "",
    conMontoApagar: "",
    conCiclosPago: "",
    servicios:[],
    conInteres:0
};
export default function CrearContrato() {
    const classes = useStyles();
    let { conId:urlConId} = useParams()
    let Dispatch = useDispatch();
    const {byId: contrato,isPending,isFetching,error } = useSelector(st=> st.contratos)
    const [state, setState] = useState(initialState);


    useEffect(()=>{
        if(urlConId) Dispatch(mostrarContratoById(urlConId));
    },[urlConId,mostrarContratoById, Dispatch])
    useEffect(()=>{
        if(contrato?.conId === parseInt(urlConId)) {
            setState({...initialState,
                conId: contrato.conId,
                conTipo: contrato.conTipo,
                conReferencia:contrato.conReferencia,
                inmId: contrato.inmId,
                inmReferencia:contrato.inmReferencia,
                cliId: contrato.cliId,
                cliNombre:contrato.cliNombre,
                conFechaProximoPago: contrato.conFechaProximoPago,
                conRenovacion: contrato.conRenovacion,
                conMontoInicial: contrato.conMontoInicial,
                conMontoApagar: contrato.conMontoApagar,
                conCiclosPago: contrato.conCiclosPago,
                servicios:contrato.servicios,
                conInteres:contrato.conInteres
            });
        }else{
            setState(initialState)
        }
    },[urlConId,contrato])


    const handleChange = (e)=>{
        const {name,value} = e.target
        return setState({...state,[name]: value});
    }

    const handleSubmit = e => {
        e.preventDefault();
        Dispatch(agregarEditarContrato(state))
    };

    return (
        <>
            <AppBar title={(Boolean(state.conId)?Strings.editar:Strings.agregar)+" "+Strings.contrato} >
                <Button color="inherit" disabled={isPending} type="submit" form="form1" value="Submit">
                    {isPending? (
                        <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                    ):(
                        <span>{Strings.guardar}</span>
                    )}
                </Button>
            </AppBar>

            <div className={"contentInner " + classes.root}>
                <MostrarError errors={error}/>
                {(isFetching || isPending) && <LoadingProgress vh/>}
                <form onSubmit={handleSubmit} id="form1">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <SearchInmueble
                                value={state.inmReferencia}
                                getProps={{inmPrecioAlquiler:"conMontoApagar"}}
                                required
                                disabled={Boolean(state.conId)}
                                onChange={(v)=>{
                                    setState(prev => ({...prev,...v}))
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SearchCliente
                                filter={{tipo: 1}}
                                value={state.cliNombre}
                                required
                                disabled={Boolean(state.conId)}
                                onChange={(v)=>
                                    setState(prev => ({...prev,cliId:v.cliId,cliNombre:v.cliente}))
                                }
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <TextField
                                name={"conReferencia"}
                                value={state.conReferencia}
                                label={Strings.referencia}
                                disabled={Boolean(state.conId)}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        {!state.conId && <>
                            <Grid item xs={6} sm={4} md={3}>
                                <DateInput
                                    name={"conFecha"}
                                    value={state.conFecha}
                                    label={Strings.fecha}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                        </>}
                        <Grid item xs={6} sm={4} md={3}>
                            <DateInput
                                name={"conFechaProximoPago"}
                                value={state.conFechaProximoPago}
                                label={Strings.fecha+" "+(state.conId ? Strings.proximo+" "+Strings.pago : Strings.primerPago)}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6} sm={4} md={3}>
                            <SelectEnum group={"ciclosRenovacion"}
                                        name={"conRenovacion"}
                                        label={"Renovación"}
                                        value={state.conRenovacion}
                                        onChange={handleChange}
                                        required
                            />

                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <TextFieldCurrency
                                name="conMontoInicial"
                                label={"Inicial / Deposito:"}
                                value={state.conMontoInicial}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <TextFieldCurrency
                                name="conMontoApagar"
                                label={"Monto a pagar:"}
                                value={state.conMontoApagar}
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <SelectUsoMultiples label={Strings.cicloPagos} name="conCiclosPago" value={state.conCiclosPago} group="ModalidadPago" onChange={handleChange} required/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <SelectConServicios value={state.servicios}
                                                onChange={(v=>setState({...state,servicios:v}))} />
                        </Grid>
                    </Grid>


                </form>

            </div>
        </>
    );

}

