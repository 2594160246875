import { servicioContratoTypes } from './types';
import { getConServicios, addConServicio, editConServicio, delConServicio } from '../../services';
import _ from 'lodash';
import history from "../../helpers/history"

export function mostrarConServicios() {
    return dispatch => {
        dispatch({
            type: servicioContratoTypes.MOSTRAR_SERVICIOS_CONTRATO,
            payload: getConServicios()
        })
    }
}
export function mostrarServiciosSinoEsta() {
    return (dispatch, getState) => {
        const servicios = getState().servicios;
        if (Object.keys(servicios.list).length || servicios.isFetching) return false;
        dispatch({
            type: servicioContratoTypes.MOSTRAR_SERVICIOS_CONTRATO,
            payload: getConServicios()
        })
    }
}
export function eliminarConServicio(id) {
    return dispatch => {
        dispatch({
            type: servicioContratoTypes.ELIMINAR_SERVICIO_CONTRATO,
            payload: delConServicio(id)
        })
    }
}
export function agregarEditarConServicio(obj) {
    let cleanObj = _.omitBy(obj, (v) => !v);
    return dispatch => {
        dispatch({
            type: servicioContratoTypes.AGREGAR_EDITAR_SERVICIO_CONTRATO,
            payload: cleanObj.serId ? editConServicio(cleanObj) : addConServicio(cleanObj)
        }).then(history.goBack)
    }
}