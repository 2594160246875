import Swal from 'sweetalert2'
import _ from "lodash";
import {base_url} from "../services"
import {getIdToken} from "../services/auth";
import moment from "moment";
import {isMobile} from "./browser_detect";
import Strings from "../assets/strings";
import loadingGif from "../assets/img/loading.gif";

import * as download from "downloadjs";
import axios from "axios";

export default function index(filter,endPoint,opcion) {
    if(!endPoint) return false;
    let cors = true;
    let params = _.omitBy(filter, (v) => !v);
    let url = base_url;
    let reportsUrl = "https://reports.prestamistapp.com";

    let fileName;

    switch (endPoint) {
        case "pagos":
            url += "Reportes/Pagos";
            fileName = "Lista de Pagos";
            break;
        case "clientes":
            url += "Reportes/Clientes";
            fileName = "Lista de Clientes";
            break;
        case "inmuebles":
            fileName = "Lista de inmuebles";
            if(opcion === "2"){
                fileName +="-pagos";
                url += "/Reportes/InmueblesConPagos";
            }else if(opcion === "3"){
                fileName +="-DataCredito";
                url += "/Reportes/InmueblesDataCredito";
            }else {
                url += "/Reportes/Inmuebles";
            }
            break;
        case "cliente":
            cors = false;
            fileName = "cliente";
            url = reportsUrl+"/ReportViewer.aspx";
            params.rpt = "rptHojaCliente";
            break;
        case "rptDetalleInmueble":
            cors = false;
            fileName = "detalleInmueble";
            url = reportsUrl+"/ReportViewer.aspx";
            params.rpt = "rptDetalleInmueble";
            break;
        case "rptAmortizacion":
            cors = false;
            fileName = "Amortizacion";
            url = reportsUrl+"/ReportViewer.aspx";
            params.rpt = "rptAmortizacion";
            break;
        case "resumenGrafico":
            fileName = "Ingresos del "+filter.anio;
            url += "/reportes/ResumenGeneral";
            break;
        default:
            url += ""
    }

    Swal.fire({
        //backdrop: false,
        //backdrop: "transparent",
       // allowOutsideClick:false,
        showConfirmButton: false,
        showCloseButton: true,
        focusConfirm:true,
        width: 340,
        html: `<div id="swalLoadingCont">
                <img src="${loadingGif}" alt="Loading" width="50">
                <p>Generando archivo…<p>
              </div>`
    });

    if(cors){
        axios.get(url,{
            responseType: 'blob',
            headers: {'contentType': 'application/json; charset=utf-8', Authorization: `Bearer ${getIdToken()}`},
            params
        }).then((res)=>{
            if (isMobile.AndroidWebView()) {
                var reader = new FileReader();
                reader.readAsDataURL(res.data);
                reader.onloadend = function() {
                    let base64data = reader.result.split(',')[1];
                    window.Android.downloadFromBase64(base64data,fileName+"-"+ moment(new Date()).format("DD-MM-YYYY")+".xlsx")
                }
            }else{
                 download(res.data, fileName +"-"+ moment(new Date()).format("DD-MM-YYYY"), res.headers['content-type']);
            }

        }).finally(()=>Swal.close())
    }else{
        params.tk = getIdToken();
        url = new URL(url);

        url.search = new URLSearchParams(params).toString();
        fetch(url,{
            method: 'GET',
        }).then(response => response.blob())
            .then(blob => {
                let fullFileName = fileName+"-"+ moment(new Date()).format("DD-MM-YYYY")+".pdf"
                let swalLoadingCont = document.getElementById("swalLoadingCont");
                if(swalLoadingCont){
                    var url = window.URL.createObjectURL(blob);

                    var btn1 = document.createElement('button');
                    btn1.style = "background-color: rgb(76, 175, 80); color: white; padding: 9px 16px; margin: 4px 2px; border: none; font-size: .8em;cursor: pointer;"
                    btn1.textContent = isMobile.anyWebView() ? Strings.compartir:Strings.abrir;
                    btn1.onclick = ()=>{
                        if(isMobile.anyWebView()){
                            var reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onloadend = function() {
                                let base64data = reader.result.split(',')[1];
                                if (isMobile.AndroidWebView()) {
                                    window.Android.shareFromBase64(base64data,fullFileName);
                                }else if(isMobile.IosWebView()){
                                    window.webkit.messageHandlers.shareFromBase64.postMessage(base64data,fullFileName);
                                }
                            }
                        }else{
                            window.open(url,"_blank")
                        }
                    }

                    var btn2 = document.createElement('button');
                    btn2.style = "background-color: rgb(76, 175, 80); color: white; padding: 9px 16px; margin: 4px 2px; border: none; font-size: .8em;cursor: pointer;"
                    btn2.textContent = Strings.descargar;
                    btn2.onclick = ()=>{

                        if(isMobile.anyWebView()){
                            var reader = new FileReader();
                            reader.readAsDataURL(blob);
                            reader.onloadend = function() {
                                let base64data = reader.result.split(',')[1];
                                if (isMobile.AndroidWebView()) {
                                    window.Android.downloadFromBase64(base64data,fullFileName);
                                }else if(isMobile.IosWebView()){
                                    window.webkit.messageHandlers.downloadFromBase64.postMessage(base64data,fullFileName);
                                }
                            }
                        }else{
                            download(blob, fullFileName);
                        }


                    };




                    swalLoadingCont.innerHTML = "";


                    swalLoadingCont.appendChild(btn1);
                    swalLoadingCont.appendChild(btn2);


                    //
                    // if(isSafari){
                    //     download(blob, fileName +"-"+ moment(new Date()).format("DD-MM-YYYY"));
                    //     Swal.close()
                    //
                    // }else {
                    //     var a = document.createElement('a');
                    //     a.target= '_blank';
                    //     a.href= url;
                    //     a.style = "background-color: #4CAF50; color: white; padding: 9px 16px; text-align: center; text-decoration: none; display: inline-block; margin: 4px 2px;"
                    //     a.text = "Ver documento";
                    //     a.onclick = Swal.close;
                    //     swalLoadingCont.innerHTML = "";
                    //     swalLoadingCont.appendChild(a);
                    //
                    //     var opened = window.open(url, '_blank');
                    //     if(opened){
                    //         Swal.close()
                    //     }
                    // }

                    //
                    // window.open(url)
                    // a.click();
                }
            }).catch(()=>Swal.close())
    }
};
const downloadBase64 = (base64,name)=>{
    if (isMobile.AndroidWebView()) {
        window.Android.downloadFromBase64(base64.split(',').pop(),name)
    }else{
        download(base64, name);
    }
}
export {downloadBase64}