import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mostrarEnumsSinoEsta } from '../../redux/actions/enumsActions';
import {FormControl, InputLabel} from "@material-ui/core";
import Strings from "../../assets/strings"
import {SelectSmart} from "../CustomTextField";
import _ from "lodash";

function SelectEnum({required,margin="none",value="", name="estado",label = Strings.estado, all, onChange, group, enums, mostrarEnumsSinoEsta}) {


    useEffect(() => {
            mostrarEnumsSinoEsta()
    }, [mostrarEnumsSinoEsta]);



    var options = (enums.list[group] && enums.list[group].map(opc=>({key:opc.key,value: Strings[_.camelCase(opc.value)]}))) || [];
    options.unshift({key:"",value:all?Strings.todo:Strings.seleccione})



    if(!onChange){
        let opcion = options.find(o=>o.key.toString() === value.toString())


        if(opcion) return  <>{opcion.value}</>;
        return <>-</>

    }
    return (
        <FormControl fullWidth required={required} margin={margin} >
            <InputLabel shrink>{label}</InputLabel>
            <SelectSmart
                value={value.toString()}
                onChange={onChange}
                name={name}
                loading={enums.isFetching }
                required={required}
                opciones={options}
            />
        </FormControl>
    );
}

const mapStateToProps = (state) => ({
    enums: state.enums,
})
export default connect(mapStateToProps, { mostrarEnumsSinoEsta })(SelectEnum);
