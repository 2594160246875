import React from 'react';
import {Button,Hidden,makeStyles} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  btn: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 39,
    }
  }
}));
export default function ButtonResponsive({icon,text,onClick,...props}){
  const classes = useStyles();
  return (
            <Button onClick={onClick} {...props} className={classes.btn}>
              <Hidden smDown>{text}</Hidden>
              <Hidden mdUp>{icon}</Hidden>
            </Button >
      );
}

