import React, {useState,useEffect} from 'react';
import {
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    Dialog,
    makeStyles,
    ListItemIcon, Checkbox, DialogActions, Button, Input, DialogContent
} from "@material-ui/core";
import { mostrarServiciosSinoEsta } from '../../redux/actions/serviciosContratoActions';

import Strings from "../../assets/strings";
import {connect} from "react-redux";
const useStyles = makeStyles(theme => ({
    status:{
        fontSize: ".8rem",
        // display: "block",
        // width: "100%",
        // padding: "0px 8px",
        // border: 14,
        // borderLeft: "2px solid transparent",

        "&.e0":{
            color:"red",
            borderColor: "red"
        },
        "&.e1":{
            color:"#54e69d",
            borderColor: "#54e69d"
        },
        "&.e2":{
            color:"orange",
            borderColor: "orange"
        }
    },
    loading:{
        width: "13px !important",
        height: "13px !important",
        position: "absolute",
        right: 12,
        top: 15,
    },
    overClick:{
        position: "absolute", height: "100%", width: "100%",zIndex: 1, cursor: "pointer"
    }
}));


function Index({ mostrarServiciosSinoEsta,servicios:{list},onChange, name,value=[],cliente,required,disabled= false}) {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false)


    useEffect(() => {
        mostrarServiciosSinoEsta();
    }, [mostrarServiciosSinoEsta]);

    const handleListItemClick = v => {
       // console.log(value)
        let list = value;
        let found = list.findIndex(l=>l.serId === v.serId);
        if(found >= 0)
            list.splice(found,1)
        else
            list.push(v)

         if(onChange)onChange(list);
    };
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    return (
        <>
            <FormControl fullWidth {...((!value) && {required})}  disabled={disabled}>
                <div className={classes.overClick} {...((!disabled) && { onClick: handleOpenDialog})}/>
                <InputLabel htmlFor="cliId" style={disabled?{}:{color:"inherit"}}>{Strings.serviciosContrato}</InputLabel>
                <Input value={value.length+" items Seleccionado"}/>
            </FormControl>
            <Dialog onClose={()=>setOpenDialog(false)}  fullWidth maxWidth={"xs"}  open={openDialog}>
                <DialogContent>
                    <List>
                        {list?.map(c => (
                            <ListItem dense button onClick={() => handleListItemClick(c)} key={c.serId}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={!!value?.find(s=>s.serId === c.serId)}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                </ListItemIcon>
                                <ListItemText primary={c.serDescripcion}/>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" onClick={()=>setOpenDialog(false)} >Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
const mapStateToProps = (state) => ({
    servicios: state.servicios,
})
export default connect(mapStateToProps, { mostrarServiciosSinoEsta })(Index);
