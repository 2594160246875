import React from 'react';
import {Tooltip, withStyles} from '@material-ui/core';



const StyleTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.info.main,//theme.palette.common.white,
    color: theme.palette.common.white,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(13),
 //   border: '1px solid '+ theme.palette.grey[300],
    fontWeight: "normal",
  },
  arrow: {
    color:  theme.palette.info.main,
  },
}))(Tooltip);


export default function HtmlTooltip({children,...props}){
  return (
      <StyleTooltip
          {...props}
          arrow
      >
        {children}
      </StyleTooltip>
      );
}

