//import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './redux/store';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


// //auth -----------------------
// //import jwt_decode from 'jwt-decode';
// //import setAuthToken from './setAuthToken';
// import { setCurrentUserInfo } from './redux/actions/authentication';
// //import { mostrarUsosMultiples } from './redux/actions/usosMultiplesActions';
// //end auth -----------------------


//auth -----------------------
import {mostrarPerfil } from './redux/actions/authentication';
import {setInstallBannerEvent } from './redux/actions/themeActions';
import Strings from "./assets/strings";
import {getLSLang} from "./helpers";
//end auth -----------------------


if(localStorage._token) {
    store.dispatch(mostrarPerfil()); // to get the color en currency

    //setAuthToken(localStorage.jwtToken);
  //  const decoded = jwt_decode(localStorage._token);
   // store.dispatch(setCurrentUserInfo(localStorage._token));
    //store.dispatch(mostrarUsosMultiples());
    
    //const currentTime = Date.now() / 1000;
    // if(decoded.exp < currentTime) {
    //   store.dispatch(userLogout());
    //   window.location.href = '/login'
    // }
}
//setear idioma
let lang = getLSLang("l");
Strings.setLanguage(lang);

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
ReactDOM.render(
    //  <React.StrictMode>
    <Provider store={store}><App /></Provider>,
    document.getElementById('root')
);

serviceWorkerRegistration.register();

window.addEventListener('beforeinstallprompt', function (e) {
    e.preventDefault();
    store.dispatch(setInstallBannerEvent(e));
});
