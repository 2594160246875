import { clienteTypes } from './types';
import {getClientes,addCliente ,editCliente, delCliente} from '../../services';
import history from "../../helpers/history"



export function mostrarClientes(obj) {
    return (dispatch,getState) => {
        //if (Object.keys(getState().clientes.list).length) return false;
        dispatch({
            type: clienteTypes.MOSTRAR_CLIENTES,
            payload: getClientes(obj)
        })
    }
}
export function mostrarClienteById(id) {
    if(!id)
        return dispatch => { dispatch({ type: clienteTypes.LIMPIAR_CLIENTE_POR_ID}) }
    return (dispatch, getState) => {
        try {
            let byId = getState().clientes.list.results.find(c=>c.cliId === id);
            if(byId)
                dispatch({ type: clienteTypes.MOSTRAR_CLIENTE_POR_ID_ENCONTRADO, payload:{byId}}) ;        }
        catch(err) {}

        dispatch({
            type: clienteTypes.MOSTRAR_CLIENTE_POR_ID,
            payload: getClientes(parseInt(id))
        })
    }
}
export function agregarEditarCliente(obj) {
    return (dispatch) => {
        dispatch({
            type: clienteTypes.AGREGAR_EDITAR_CLIENTE,
            payload: obj.cliId ? editCliente(obj) : addCliente(obj)
        }).then(()=>{
            history.replace("/clientes",null);
        })
    }
}
export function eliminarCliente(id) {
    return dispatch => {
        dispatch({
            type: clienteTypes.ELIMINAR_CLIENTE,
            payload: delCliente(id)
        }).then(()=>{
            history.replace("/clientes",null);
        })
    }
}

// export function toggleDetailsModal(id) {
//     return (dispatch, getState) => {
//         if (getState().inmuebles.showDetailsModal) {
//             dispatch({ type: CLOSE_MODAL })
//         } else if (id > 0) {
//             let itemToView = getState().inmuebles.list.results.find(e => e.cliId === id);
//             dispatch({ type: OPEN_MODAL, payload: itemToView })
//             dispatch({
//                 type: clienteTypes.MOSTRAR_INMUEBLE_DETALLES,
//                 payload: getClientes(id)
//             })
//         }
//     }
// }