import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress, Backdrop} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    opacity: "0.5 !important" ,
    background: "white",
    position: "absolute",
  },
}));
export default function LoadingProgress(props){
      const classes = useStyles();
      return (
          <Backdrop className={classes.backdrop} open={true} style={props.vh?{height: "calc(100vh - 115px)"}:{}}>
            <CircularProgress />
          </Backdrop>
      );
}
