import React from "react";
import {Slide} from "@material-ui/core";
import SuccessMsj from "./success"
export const SlideLeft = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export function SlideTransition(direction){
    return React.forwardRef(function Transition(props, ref) {
        return <Slide direction={direction} ref={ref} {...props} />;
    });
}
export {SuccessMsj}