import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {mostrarContratoById} from "../../redux/actions/contratoActions"
import {useHistory} from "react-router-dom";
import {addPago} from "../../services"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  makeStyles,
  TextField,
  useMediaQuery
} from '@material-ui/core';
import {DateInput, TextFieldCurrency} from "../../components/CustomTextField"
import {formatPeso, hasPermissionTo, PERSMISOS} from "../../helpers";
import {HtmlTooltip, LoadingProgress, MostrarError, TopBarDialog} from "../../components";
import Strings from "../../assets/strings";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
      maxWidth:540,
      margin:"0px auto",
    },
    [theme.breakpoints.only('xs')]: {
      "& .MuiInput-input":{
        position: "relative",
        padding: 7,
        border: "1px solid #e6e6e6",
      },
      "& .MuiInputLabel-formControl:not(.MuiInputLabel-shrink)":{
        left:theme.spacing(1),

      }
    }
  },
  tabs:{
    minHeight: 37,
    "& button":{
      minHeight: 37,
      "&.Mui-selected":{
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderTop: "1px solid rgba(0, 0, 0, 0.1)"
      }
    },
    "& .MuiTabs-indicator":{
      display:"none",
    }
  },
  content:{
    padding:theme.spacing(2),
  },
  cuotaInteres:{
    display: "flex",
    justifyContent: "flex-end",
    fontSize: ".9em",
    fontWeight: 600,
    textAlign: "center",
    marginTop: 40,
    marginBottom: 4,
    "& span":{
      fontWeight: "normal",
    },
    "&> div": {
      "&:first-child":{
          marginRight: "auto"
        },
      "&:nth-child(2)": {
          marginRight: 5
        }
    }
  },
  montoInput:{
    background: "#f3f3f3",
    padding: theme.spacing(2),
    textAlign: "center",
    marginBottom: theme.spacing(2),
    "& .MuiInput-root":{
      margin:"0px !important",
    },
    "& input": {
      fontSize: "1em",
      color:theme.palette.primary.main,
      textAlign: "center",
      background: theme.palette.common.white,

      "&:disabled":{
        background: theme.palette.grey[100]
      }
    }
  },
  dialogActions:{
    justifyContent: "space-between",
    background: "#f8f8f8",
  },
  totalApagar:{
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
    "& span":{
      color:theme.palette.secondary.main
    }
  }
}));
let initialState = {
  pagNoCuota: 1,
  pagFecha: new Date(),
  pagMonto: 0,
  pagMora:0,
  pagDescuento:0,
  pagNota:""
};
function AddPago({contrato,mostrarContratoById}){
  let history = useHistory();
  const classes = useStyles();
  const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));
  const [state, setState] = useState(initialState);
  const [cuotasInputFocus, setCuotasInputFocus] = useState(false);

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [nextConMontoPendiente, setNextConMontoPendiente] = useState(0);



  useEffect(()=>{
    if (contrato.conId) {
      let obj={
        conId:contrato.conId,
        pagMora:contrato.conMora,
        pagMonto:contrato.conMontoApagar + (contrato.conMontoPendiente * -1),
        pagFecha:contrato.currentDate
      };
      setState(prevState => ({...prevState,...obj}));
    }
  },[contrato, setState]);

  useEffect(()=>{
    const { pagMonto,pagNoCuota} = state
    let pendiente = contrato.conMontoPendiente;
    if(parseFloat(pagMonto) !== (contrato.conMontoApagar * pagNoCuota)){
      let restatante =  parseFloat(pagMonto) - (contrato.conMontoApagar * pagNoCuota);
      pendiente = contrato.conMontoPendiente + restatante;
    }

    if(!isNaN(pendiente)){
      setNextConMontoPendiente(pendiente)
    }
  },[contrato, state,setNextConMontoPendiente ]);


  const handleChange = e => {
    const {value,name} = e.target;
    if(name === "pagNoCuota"){
      return setState({
        ...state,
        pagMonto: (contrato.conMontoApagar * value) + (contrato.conMontoPendiente * -1),
        [name]:value
      });
    }
    setState({...state,[name]:value});
  };
  const submit = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);

    addPago(state)
    .then(({data})=>{
      setIsPending(false);
      mostrarContratoById(contrato.conId);
      history.replace(history.location.pathname,{recibo:data})
    }).catch(({response})=>{
      setError(response?.data);
      setIsPending(false)
    })
  };

  return (
      <Dialog className={classes.root} fullScreen={smVewport} open={true} onClose={history.goBack}>
        <TopBarDialog title={Strings.agregar+" "+Strings.pago} nofullScreen onClose={history.goBack}/>
        <MostrarError errors={error}/>
        <DialogContent className={classes.content}>
          {isPending && <LoadingProgress vh={false} />}
          <Grid container spacing={3} style={{position:"relative", marginBottom:8}}>
            <Grid item xs={6}>
                <TextField
                    value={(cuotasInputFocus ? state.pagNoCuota : state.pagNoCuota+ " "+Strings.cuotas)}
                    type={cuotasInputFocus ? "number":"Text"}
                    onFocus={()=>setCuotasInputFocus(true)}
                    onBlur={()=>setCuotasInputFocus(false)}
                    name={"pagNoCuota"}
                    onChange={handleChange}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
              <DateInput
                  name="pagFecha"
                  value={state.pagFecha}
                  margin={"none"}
                  onChange={handleChange}
                  disabled={!hasPermissionTo(PERSMISOS.pagosCambiarFecha)}
              />
            </Grid>
          </Grid>
          <div className={classes.cuotaInteres}>
            <div>
              {contrato.conMontoPendiente < 0 ?Strings.pendiente:"Acumulado"}
              <span> {formatPeso(Math.abs(contrato.conMontoPendiente))}</span>
            </div>
            <div>
              {Strings.cuota}: <span>{formatPeso(contrato.conMontoApagar)}</span>
            </div>
            <div>
              {Strings.mora}: <span>{formatPeso(contrato.conMora)}</span>
            </div>
          </div>
          <form onSubmit={submit} id={"formMonto"}>
            <div className={classes.montoInput}>
              <TextFieldCurrency id="pagMonto" fullWidth name="pagMonto" min={1} value={state.pagMonto}
                                 onChange={handleChange}/>
            </div>
          </form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextFieldCurrency disabled={!hasPermissionTo(PERSMISOS.pagosModificarMora)}
                  label={Strings.mora} name="pagMora" value={state.pagMora} onChange={handleChange} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextFieldCurrency disabled={!hasPermissionTo(PERSMISOS.pagosDescuento)}
                  label={Strings.descuento} name="pagDescuento" value={state.pagDescuento} onChange={handleChange} fullWidth/>
            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <TextField label={Strings.comentario} multiline name="pagNota" value={state.pagNota}
                           onChange={handleChange}
                           inputProps={{maxLength: 256}}
                           margin="normal" fullWidth/>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        {nextConMontoPendiente !== 0 &&
          <Alert icon={false} severity="info"  style={{justifyContent:"center"}}>
            {formatPeso(Math.abs(nextConMontoPendiente))} quedará <b>{nextConMontoPendiente < 0 ?Strings.pendiente:"Acumulado"}</b> para el próximo pago.
          </Alert>
        }
        <DialogActions className={classes.dialogActions}>
          <HtmlTooltip title={Strings.totalApagar} >
            <div className={classes.totalApagar}>
              {Strings.total}: <span> {formatPeso(parseFloat(state.pagMonto || 0) +
                  parseFloat(state.pagMora || 0) -
                  parseFloat(state.pagDescuento || 0))}</span>
            </div>
          </HtmlTooltip>
          <Button color="primary" type={"submit"} form={"formMonto"} disabled={isPending}>{Strings.agregar} {Strings.pago}</Button>
        </DialogActions>
      </Dialog>
  );
}
const mapStateToprops = (state) => ({
  contrato:state.contratos.byId,
});
export default connect(mapStateToprops,{mostrarContratoById})(AddPago);
