import React, {useEffect, Fragment, useState} from 'react';
import { GoogleMap,Marker,InfoWindow } from '@react-google-maps/api';
import { mostrarAgentesSinoEsta} from '../../redux/actions/agentesActions';

import {
    Divider,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Typography,
    Backdrop,
    Snackbar, useMediaQuery
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import Strings from "../../assets/strings";
import {getInmuebles} from "../../services";
import {useHistory} from "react-router-dom";
import { useDispatch} from 'react-redux'
import {AppBar, ButtonResponsive} from "../../components";
import {FilterList,Help} from "@material-ui/icons";
import {noTilde} from "../../helpers";
import {getIcon} from "../../assets/iconPath";
import useGeolocation from 'react-hook-geolocation'


const initialCenter = {
    lat: 0,
    lng: 0
};

const useStyles = makeStyles(theme => ({
    root:{
        padding: 0,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        background: 'transparent',
    },
    searchBox:{
        top: "60px",
        right: "10px",
        width: "229px",
        maxHeight: "50%",
        position: "absolute",
        background: "white",
        overflow: "auto",
        zIndex: 1101,
        "&>div":{
            marginBottom: -10,
        },
        "& input":{
            padding: "10px 15px",
            width: "calc(100% - 4px)",
            boxSizing: "border-box",
            margin: "2px",
            border: "none",
            outline: "none",
            fontSize: "1em",
            background: "#eaeaea",
        }
    },
}));
function pinSymbol(proColor,proIcon) {
    let color = proColor ? proColor : "#2196f3";//"#b81717";
    let icon = proIcon ? proIcon : "House";

    return {
        path:getIcon(icon),
        scale: 2, // > 1 to make it bigger
        fillColor: color, // Use RGBa function
        background:color,
        fillOpacity: 1,
        strokeColor: 'hsl(0,0%,100%)', // Use HSLa function
        anchor: { x: 12, y: 24 },
    };
}
function Map() {
    // const agentes = useSelector(state => state.agentes.list)
    const geolocation = useGeolocation({
       enableHighAccuracy:true
    });

    let history = useHistory();
    const dispatch = useDispatch()
    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));

    const classes = useStyles();
    const [infoWin , setInfoWin ] = React.useState(null);
    const [inmuebles, setInmuebles ] = React.useState([]);
    const [inmuebleFilter, setInmuebleFilter ] = React.useState([]);

    const [openFilter, setOpenFilter] = useState(false);
    const [snackbar, setSnackbar] = useState(null);

    const [center, setCenter ] = React.useState(null);
    const [currentPoint, setCurrentPoint ] = React.useState(null);



    useEffect(()=>{
        dispatch(mostrarAgentesSinoEsta())
        getInmuebles()
            .then(res=>{
                let data = res?.data?.results ?? [];
                data = data.map((i)=>{
                    let inmCoordenadas = null;
                    if(i.inmCoordenadas && i.inmCoordenadas.indexOf(",") !== -1){
                        let latLng = i.inmCoordenadas.split(",");
                        inmCoordenadas = {
                            lat: parseFloat(latLng[0]),
                            lng: parseFloat(latLng[1]),
                        };
                    }
                    return {...i,inmCoordenadas}
                })
                setInmuebles(data)
            })
    },[setInmuebles,dispatch])

    useEffect(()=>{
        if(geolocation?.latitude){
            let cp = {
                lat: geolocation.latitude,
                lng: geolocation.longitude
            }
            setCurrentPoint(cp);

            if(!center?.lat)  setCenter(cp);

        }else if(inmuebles.length){
            if(!center?.lat) setCenter(inmuebles.find(e=>e.inmCoordenadas !== null)?.inmCoordenadas);
        }

    },[geolocation,center,inmuebles, setCenter])

    const handleFilterChange = (e)=>{
        const {value} = e.target
        let filtro = inmuebles.filter((i)=>noTilde(i.inmDescripcion).indexOf(noTilde(value)) !== -1);
        return setInmuebleFilter(filtro.slice(0,20));
    }


    return window?.google?.maps ? <>
        <AppBar title={Strings.mapa}>
            <ButtonResponsive icon={<Help/>} text={Strings.ayuda} onClick={() => window.open("https://youtu.be/82YgTriT5So","_blank")}/>
            <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
        </AppBar>
        <section className={"contentInner "+classes.root}>
            <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: `calc(100vh - ${smVewport?"50":"115"}px)`
                }}
                center={center || initialCenter}
                zoom={13}
            >
                {inmuebles?.filter(e=> !!e.inmCoordenadas).map(c =>
                    <Marker
                        key={c.inmId}
                        position={{
                            lat: c.inmCoordenadas?.lat,
                            lng: c.inmCoordenadas?.lng
                        }}
                        title={c.inmDescripcion}
                        onClick={()=>setInfoWin(infoWin === c.inmId ? null:c.inmId)}
                        animation={infoWin === c.inmId && window.google.maps.Animation.ep}
                        icon={pinSymbol(c.gruColor,c.gruIcon)}
                    >
                        {(infoWin === c.inmId || infoWin === -1) &&
                        <InfoWindow onCloseClick={()=>setInfoWin(null)}>
                            <div onClick={()=>history.push(`Inmueble/${c.inmId}`)}>
                                <Typography>{c.inmDescripcion}</Typography>
                                <Typography color={"primary"} variant={"body2"}>Mas información</Typography>
                            </div>
                        </InfoWindow>}
                    </Marker>
                )}



                {currentPoint &&
                    <Marker
                        position={currentPoint}
                        title={"Yo"}
                        onClick={()=>setInfoWin(infoWin === -2 ? null: -2)}
                        icon={pinSymbol(null,"PersonPinCircle")}
                    >
                        {(infoWin === -2 || infoWin === -1) &&
                        <InfoWindow onCloseClick={()=>setInfoWin(null)}>
                            <>
                                <Typography color={"primary"}>{Strings.ubicacionActual}</Typography>
                                <p>{Strings.ubicacionActualMjs}</p>
                            </>
                        </InfoWindow>}
                    </Marker>
                }
            </GoogleMap>
            <Backdrop className={classes.backdrop} open={openFilter} onClick={()=>setOpenFilter(false)}>

                <div className={classes.searchBox} onClick={(e)=>e.stopPropagation()}>
                    <div onClick={(e)=>e.stopPropagation()}>
                        <input type="text" onChange={handleFilterChange}
                               placeholder={Strings.buscar} autoFocus/>
                    </div>
                    <List>
                        {inmuebleFilter?.map(c =><Fragment key={c.inmId}>
                            <ListItem button onClick={()=>{
                                if(c.inmCoordenadas){
                                    setInfoWin(c.inmId);
                                    setCenter(c.inmCoordenadas);
                                    setOpenFilter(false)
                                }else{
                                    setSnackbar(Strings.noMapMjs)
                                }
                            }} >
                                <ListItemText>
                                    <Typography noWrap>
                                        {c.inmDescripcion}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider component="li" />
                        </Fragment>)}

                        {/*{agentes?.map(a =>*/}
                        {/*    <Fragment key={a.usuInicioSesion}>*/}
                        {/*    <ListItem  button >*/}
                        {/*        <ListItemText>*/}
                        {/*            <Typography noWrap>*/}
                        {/*                {a.usuNombres} {a.usuApellidos}*/}
                        {/*            </Typography>*/}
                        {/*        </ListItemText>*/}
                        {/*    </ListItem>*/}
                        {/*    <Divider component="li" />*/}
                        {/*    </Fragment>)}*/}
                    </List>
                </div>
            </Backdrop>
            <Snackbar open={!!snackbar} autoHideDuration={2000}
                      anchorOrigin={{ vertical:'top', horizontal:'center' }}
                      onClose={()=>setSnackbar(null)}>
                <Alert severity="warning">
                    {snackbar}
                </Alert>
            </Snackbar>
        </section>
    </> :<></>
}

export default React.memo(Map)
