import {agenteTypes} from './types';
import {addAgente, editAgente, getAgentes, lockToggleAgente, delAgente,editPWAgente} from '../../services';
import history from "../../helpers/history";
export function mostrarAgentes() {
    return dispatch => {
        dispatch({
            type: agenteTypes.MOSTRAR_AGENTES,
            payload: getAgentes()
        })
    }
}
export function mostrarAgentesSinoEsta() {
    return (dispatch, getState) => {
        const agentes = getState().agentes
        if (Object.keys(agentes.list).length || agentes.isFetching) return false;
        dispatch({
            type: agenteTypes.MOSTRAR_AGENTES,
            payload: getAgentes()
        })
    }
}
export function agregarEditarAgente(obj) {
    return dispatch => {
        dispatch({
            type: agenteTypes.AGREGAR_EDITAR_AGENTE,
            payload: obj.usuId ? editAgente(obj) : addAgente(obj)
        }).then(history.goBack)
    }
}
export function editarContrasena(obj) {
    return dispatch => {
        dispatch({
            type: agenteTypes.EDITAR_CONTRASENA_AGENTE,
            payload: editPWAgente(obj)
        }).then(history.goBack)
    }
}
export function toggleLock(id) {
    return dispatch => {
        dispatch({
            type: agenteTypes.LOCK_TOGGLE_AGENTE,
            payload: lockToggleAgente(id)
        }).then(history.goBack)
    }
}
export function eliminarAgente(id) {
    return dispatch => {
        dispatch({
            type: agenteTypes.ELIMINAR_AGENTE,
            payload: delAgente(id)
        }).then(history.goBack)
    }
}
