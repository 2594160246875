import React, {useState, useEffect} from 'react';
import {agregarEditarUsoMultiple} from "../../redux/actions/usosMultiplesActions";
import { Button,Dialog,DialogContent,TextField,DialogActions} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import {connect} from "react-redux";
import LoadingProgress from "../../components/LoadingProgress";
import Grid from "@material-ui/core/Grid";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {MostrarError} from "../../components";


const initialState = {
    usoGrupo:"",
    usoCodigo: "",
    usoDescripcion: ""
};
function Crear(props) {

    const {goBack} = useHistory();
    const {state:urlState} = useLocation();

    const [state, setState] = useState(initialState);
    const {isPending} = props;

    useEffect(() => {
        if(!!urlState && urlState.usoCodigo) {
            setState(state => ({...state, ...nullToEmptyString(urlState)}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);

    const handleChange = e => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };

    const handleSubmit = e => {
        e.preventDefault();
        props.agregarEditarUsoMultiple(state)
    };
    const edit = (urlState && !!urlState.usoCodigo)
    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("usoCodigo"))} onClose={goBack} maxWidth={"sm"} fullWidth={true}>
                <TopBarDialog title="Usos multiples" nofullScreen onClose={goBack}/>
                <DialogContent>
                    <MostrarError errors={props.error}/>
                    {isPending && <LoadingProgress />}
                    <form onSubmit={handleSubmit} id="form1">
                        <Grid container spacing={3}>
                            <Grid item xs={7}>
                                <TextField label="Grupo"
                                           name={"usoGrupo"}
                                           value={state.usoGrupo}
                                           fullWidth margin={"normal"}
                                           onChange={handleChange}
                                           disabled={edit}
                                           inputProps={{
                                               list:"browsers",
                                               id:"browser"
                                           }}
                                />
                                <datalist id="browsers">
                                    {Object.keys(props.grupos).map(g=>
                                        <option value={g}/>
                                    )}
                                </datalist>
                                {/*<Autocomplete*/}
                                {/*    options={Object.keys(props.grupos)}*/}
                                {/*    value={state.usoGrupo}*/}
                                {/*    freeSolo*/}
                                {/*    onChange={(event, newValue) => {*/}
                                {/*        setState({...state,usoGrupo:newValue});*/}
                                {/*    }}*/}
                                {/*    disabled={edit}*/}
                                {/*    renderInput={(params) =>*/}
                                {/*        <TextField  {...params} label="Grupo" fullWidth margin={"normal"}/>}*/}
                                {/*/>*/}
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    name="usoCodigo"
                                    value={state.usoCodigo}
                                    onChange={handleChange}
                                    required
                                    type={"number"}
                                    label="Código"
                                    fullWidth
                                    disabled={edit}
                                    margin={"normal"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="usoDescripcion"
                                    value={state.usoDescripcion}
                                    onChange={handleChange}
                                    required
                                    label="Descripción"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <br/>

                </DialogContent>
                <DialogActions>
                    <Button onClick={goBack} color="primary">Cancelar</Button>
                    <Button type="submit" form={"form1"} color="primary" disabled={isPending}>
                        {edit ? " Actualizar" : " Guardar"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}
const mapStateToProps = (state) => ({
    isPending: state.usosMultiples.isPending,
    error:state.usosMultiples.error,
    grupos: state.usosMultiples.list || {}
});
export default connect(mapStateToProps, { agregarEditarUsoMultiple })(Crear);
