import React from 'react';
import { connect } from 'react-redux';
import {Button, Hidden, Typography, Box, ButtonGroup} from '@material-ui/core';
import {toggleViewTable} from "../redux/actions/themeActions";
import {FormatListBulleted, ListAlt} from "@material-ui/icons";
import Strings from "../assets/strings";
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
    root:{
        width: "100%",
        height: "33px",
        margin: "0",
        display: "flex",
        padding: "0px 16px",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#f6f6f6",
        [theme.breakpoints.up('md')]: {
            marginTop: "65px",
        }
    }
}));


function ListViewMode({lg,viewTableList,toggleViewTable,pageRows,pageTotalRows,children}){
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Typography variant={"body2"}>{!!pageRows && <>{pageRows} {Strings.de} {pageTotalRows}</>}</Typography>
            <Box alignItems={"center"} display={"flex"}>
                {children}
                <Hidden mdUp={!lg}>
                    <ButtonGroup size="small" aria-label="small outlined button group">
                        <Button style={{marginLeft: 5}} color={viewTableList ? "primary":""}  onClick={()=> !viewTableList && toggleViewTable()}><FormatListBulleted fontSize={"small"}/></Button>
                        <Button color={!viewTableList ? "primary":""}  onClick={()=>viewTableList && toggleViewTable()} ><ListAlt fontSize={"small"}/></Button>
                    </ButtonGroup>
                </Hidden>
            </Box>


        </div>)
    //return lg ? content : <Hidden mdUp>{content}</Hidden>;
}
const mapStateToprops = (state) => ({
    viewTableList: state.theme.viewTableList
})
export default connect(mapStateToprops,{toggleViewTable})(ListViewMode);

