// import { configureStore } from '@reduxjs/toolkit'
//
// import rootReducer from './reducers/rootReducer'
// import promise from "redux-promise-middleware";
//
// export const store = configureStore({
//   reducer:rootReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(promise)
//
//   // middleware: getDefaultMiddleware({
//   //     serializableCheck: true,
//   // }),
// })
//
//
// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./reducers/rootReducer', () => {
//     const newRootReducer = require('./reducers/rootReducer').default
//     store.replaceReducer(newRootReducer)
//   })
// }
//
//
// export default store




import { createStore, applyMiddleware,compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import promise from "redux-promise-middleware";


const inititalState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, inititalState, composeEnhancers(
  applyMiddleware(promise, thunk)
  ));

export default store;
