import {gastoTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';
const initialState = {
    list: [],
    filtered:{},
    isFetching: false,
    isPending: false,
    error: null,
}
 
export default function index(state = initialState, action={}) {

    switch (action.type) {
        case gastoTypes.MOSTRAR_GASTOS + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case gastoTypes.MOSTRAR_GASTOS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                filtered: action.payload?.config?.params ?? {},
                isFetching: false,
                error: {}
            }
        }
        case gastoTypes.MOSTRAR_GASTOS + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }
        //agregar
        
        case gastoTypes.AGREGAR_GASTO + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case gastoTypes.AGREGAR_GASTO + _FULFILLED: {
            return {
                ...state,
                isPending: false,
                error: {}
            }
        }
        case gastoTypes.AGREGAR_GASTO + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }

        //Eliminar
        case gastoTypes.ELIMINAR_GASTO + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case gastoTypes.ELIMINAR_GASTO + _FULFILLED: {
            return {
                ...state,
                isPending: false,
                error: {}
            }
        }
        case gastoTypes.ELIMINAR_GASTO + _REJECTED: {
            return {
                ...state,
                isPending: false,
            }
        }

        case "resetGastosErrors": {
            return {
                ...state,
                error: null
            }
        }

        default:
      return state;
  }
}
