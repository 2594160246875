import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {delContrato} from "../../services";
import {Dialog,DialogActions,DialogContent,Checkbox,FormControlLabel,Button,makeStyles,TextField} from '@material-ui/core';
import {connect} from "react-redux";
import Strings from "../../assets/strings";
import {MostrarError,LoadingProgress,TopBarDialog} from "../../components";
const useStyles = makeStyles(theme => ({
  cancelBox:{
    "& .content":{
      padding: "10px 10px 0 10px",
    },
      "&.danger header":{
          backgroundColor: theme.palette.error.light,
      }
  }
}));


function CancelDialog(props) {
    const {goBack,...history} = useHistory();

    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [state, setState] = useState({nota:"", anularPagos:false});

    const submit = () => {
        setLoading(true)
        delContrato(props.conId,state).then(res=>{
            setLoading(false)
            history.replace("/contratos")
        }).catch(error=>{
            setError(error.response)
            setLoading(false)
        })
    };
    const handleChange = e => {
          const {name,value,checked} = e.target;
          if(name === "anularPagos"){
            setState({...state,  [name]: checked });
          }else{
            setState({...state,  [name]: value });
          }
    };

    return (
        <Dialog open={true} onClose={goBack} className={(state.anularPagos?"danger ":"")+classes.cancelBox} maxWidth={"xs"} fullWidth>
        <TopBarDialog title={Strings.canceConMsj} onClose={goBack} />

          <MostrarError errors={error}/>

          <DialogContent className="content">
          {loading && <LoadingProgress />}
              {state.anularPagos?(
                  <p>{Strings.contratoSeEliminara}</p>
              ):(
                  <TextField
                      label={Strings.comentario}
                      multiline
                      name="nota"
                      rowsMax="4"
                      value={state.nota}
                      fullWidth
                      variant={"outlined"}
                      onChange={handleChange}
                      inputProps={{maxLength: 100}}

                  />
              )}

          <FormControlLabel
              control={<Checkbox name="anularPagos" checked={state.anularPagos} onChange={handleChange}/>}
              label={Strings.eliminar+" "+Strings.pagos} />
        </DialogContent>
        <DialogActions>
          <Button onClick={goBack} color="default">{Strings.abordar}</Button>
          <Button onClick={submit} disabled={loading} color="secondary">{Strings.confirmar}</Button>
        </DialogActions>
      </Dialog>
    );
}

const mapStateToProps = (state) => ({
  conId: state.contratos.byId.conId
})
export default connect(mapStateToProps)(CancelDialog);



