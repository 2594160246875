import React, {useEffect} from 'react';
import {Editor} from '@tinymce/tinymce-react';
import LoadingProgress from "../../components/LoadingProgress";
import {getLSLang} from "../../helpers";
import Strings from "../../assets/strings";
import {useSelector} from "react-redux";

export default function ContratoEditor(props){
    const {values = {ajuPlantillaContrato:"",ajuPlantillaContrato2:"",ajuPlantillaContrato3:""},onChange} = props;
    const [loading, setLoading] = React.useState(true);

    const generales = useSelector(state => state.generales)


    const [numCon, setNumCon] = React.useState("ajuPlantillaContrato");
    const language = getLSLang("l")

    useEffect(()=>{
        setLoading(generales.isFetching)
    },[generales,setLoading])


    const setting = {
        language,
        menubar: false,
        height: 500,
        branding: false,
        inline_boundaries: false,
        inline_boundaries_selector: 'em',
        // statusbar: false,
        //
        plugins: 'code  template table searchreplace directionality insertdatetime', //insert
        toolbar: 'undo redo | template | contrato | parametros | table | formatselect  | bold italic underline strikethrough  | alignleft aligncenter alignright alignjustify  | code |  searchreplace removeformat',
        image_advtab: true,
        templates: [
            { title: Strings.contrato, description:"", url: "/contratoTemplate/"+language+"/contrato.html" },
        ],
        mobile: {
            theme: 'silver',
        },
        setup: function (editor) {
            editor.on('init', function () {
                this.getDoc().body.style.fontSize = '16px';
                this.getDoc().body.style.fontFamily = 'Georgia';
            });
            editor.ui.registry.addMenuButton('contrato', {
                text: Strings.plantilla+" "+ numCon.charAt(numCon.length-1),
                class: 'Deibi12',
                tooltip: "Editar", //<- selecciono por aqui
                fetch: function (callback) {
                    var items = [
                        {
                            type: 'menuitem',
                            text: `${Strings.plantilla} 1`,
                            onAction: function (_) {
                                setNumCon("ajuPlantillaContrato");
                                document.querySelector('[title="Editar"] > span').textContent = Strings.plantilla+" 1"
                            }
                        }, {
                            type: 'menuitem',
                            text: `${Strings.plantilla} 2`,
                            onAction: function (_) {
                                setNumCon("ajuPlantillaContrato2")
                                document.querySelector('[title="Editar"] > span').textContent = Strings.plantilla+" 2"
                            }
                        }, {
                            type: 'menuitem',
                            text: `${Strings.plantilla} 3`,
                            onAction: function (_) {
                                setNumCon("ajuPlantillaContrato3")
                                document.querySelector('[title="Editar"] > span').textContent = Strings.plantilla+" 3"
                            }
                        }
                    ];
                    callback(items);
                }
            });
            editor.ui.registry.addMenuButton('parametros', {
                text: Strings.parametros,
                fetch: function (callback) {
                    var items = [
                        {
                            type: 'nestedmenuitem',
                            text: 'Empresa',
                            getSubmenuItems: function () {
                                return [
                                    {
                                        type: 'menuitem',
                                        text: 'Nombre',
                                        onAction: function (_) {
                                            editor.insertContent(' $EmpresaNombre');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: 'Dirección',
                                        onAction: function (_) {
                                            editor.insertContent(' $EmpresaDireccion');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: 'Dirección',
                                        onAction: function (_) {
                                            editor.insertContent(' $EmpresaRNC');
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            type: 'nestedmenuitem',
                            text: 'Cliente',
                            getSubmenuItems: function () {
                                return [
                                    {
                                        type: 'menuitem',
                                        text: 'Info Completa',
                                        onAction: function (_) {
                                            editor.insertContent(' $ClienteInfo');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: 'Nombre',
                                        onAction: function (_) {
                                            editor.insertContent(' $NombreCliente');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: 'Apellido',
                                        onAction: function (_) {
                                            editor.insertContent(' $ApellidoCliente');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: 'Cédula',
                                        onAction: function (_) {
                                            editor.insertContent(' $CedulaCliente');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: 'Dirección',
                                        onAction: function (_) {
                                            editor.insertContent(' $DireccionCliente');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: 'Teléfono',
                                        onAction: function (_) {
                                            editor.insertContent(' $TelefonoCliente');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: 'Nacionalidad',
                                        onAction: function (_) {
                                            editor.insertContent(' $Nacionalidad');
                                        }
                                    }
                                ]
                            }
                        }, {
                            type: 'nestedmenuitem',
                            text: 'Contrato',
                            getSubmenuItems: function () {
                                return [
                                    {
                                        type: 'menuitem',
                                        text: 'Fecha',
                                        onAction: function (_) {
                                            editor.insertContent(' $FechaContrato');
                                        }
                                    },  {
                                        type: 'menuitem',
                                        text: 'Monto',
                                        onAction: function (_) {
                                            editor.insertContent(' $MontoContrato');
                                        }
                                    }
                                ]
                            }
                        }
                    ];
                    callback(items);
                }
            });
        }
    }

    // useEffect(()=>{
    //     tinymce.init({selector:'#mytexta'});
    // });
    //
    // if(generales.isFetching) return <>Loading</>


    if(loading) return <LoadingProgress/>;

    return(
        <div className={"contPagare"} >
            <Editor id="ajuPlantillaContrato" init={setting}
                    apiKey='wz24bwywc9e9y0hrtovishcbc4en66fzp25qnun8qjlxzkcj'
                value={values[numCon]}

                {...(onChange && {
                    onEditorChange : (content)=> onChange({target:{name: numCon, value: content}})
                })}
                    onInit={(e)=>setLoading(false)}

            />
        </div>
    )
}

