import {appUserTypes} from './types';
import {getAppUsers} from '../../services';

export function mostrarAppUsers(filter) {
    return (dispatch,getState) => {
        const appUsers = getState().appUsers;
        let paramsFilter = filter || appUsers.filtered;

        dispatch({
            type: appUserTypes.MOSTRAR_APPUSERS,
            payload: getAppUsers(paramsFilter)
        })
    }
}
