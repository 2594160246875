import { estadoTypes } from './types';
import { getEstados } from '../../services';


export function mostrarEstados() {
    return dispatch => {
        dispatch({
            type: estadoTypes.MOSTRAR_ESTADOS,
            payload: getEstados()
        })
    }
} 

export function mostrarEstadosSinoEsta() {
    return (dispatch, getState) => {
        const estados = getState().estados
        if (Object.keys(estados.list).length || estados.isFetching) return false;
        dispatch({
            type: estadoTypes.MOSTRAR_ESTADOS,
            payload: getEstados()
        })
    }
}
