import React, { useEffect} from 'react';
import { connect } from 'react-redux';
import { mostrarCaracteristicas, eliminarCaracteristica} from '../../redux/actions/inmCaractActions';
import AppBar from "../../components/appBar";
import Crear  from './crear';
import {
    ListItem,
    List,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Button,
} from "@material-ui/core";
import PlusFab from "../../components/PlusFab";
import {Close} from "@material-ui/icons";
import LoadingProgress from "../../components/LoadingProgress";
import {useHistory, useLocation} from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import {MostrarError} from "../../components/MostrarError";
import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {Empty} from "../../components";
import {ReactComponent as EmptySvg} from "../../assets/img/map.svg";

function Caracteristicas(props) {
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();

    const { mostrarCaracteristicas } = props;
    useEffect(() => {
        mostrarCaracteristicas();
    }, [mostrarCaracteristicas]);

    const { caracteristicas: { list, isFetching, error } } = props;
    const isEmpty = !isFetching && list.length === 0;

    return (
        <>
            <AppBar title={"Caracteristicas"}/>
            <section className="contentInner" style={!isEmpty ? {maxWidth:600}:{}}>
                {!urlState && <MostrarError errors={error}/>}
                {isFetching  && !list.length && <LoadingProgress vh/>}

                <List >
                    {!isEmpty && list.map(item =>
                        <ListItem role={undefined} button key={item.carId}
                                  onClick={()=>{
                                      if(!hasPermissionTo(PERSMISOS.caracteristicasActualizar) || item.registroDelSistema) return false;
                                      history.push(history.location.pathname,item)}}>
                            <ListItemText primary={item.carDescripcion} />
                            {hasPermissionTo(PERSMISOS.caracteristicasEliminar) && !item.registroDelSistema &&
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="comments" onClick={()=>history.push(history.location.pathname,{confirmDialog:item.carId})} >
                                    <Close className="text-red" fontSize={"small"}/>
                                </IconButton>
                            </ListItemSecondaryAction>
                            }
                        </ListItem>
                    )}
                </List>
                {isEmpty &&
                <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.caracteristicas}>
                    <p>{Strings.caracteristicasHelpMsj}</p>
                </Empty>
                }


                {hasPermissionTo(PERSMISOS.caracteristicasCrear) &&
                <PlusFab component={Button} onClick={()=>history.push(history.location.pathname,{carId:""})} />
                }
                <Crear/>


                <ConfirmDialog onConfirm={()=>props.eliminarCaracteristica(urlState && urlState.confirmDialog)}/>

            </section>
        </>
    );

}

const mapStateToProps = (state) => ({
    caracteristicas: state.caracteristicas
});
export default connect(mapStateToProps, { mostrarCaracteristicas, eliminarCaracteristica })(Caracteristicas);
