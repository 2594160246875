import { inmCaractTypes, _FULFILLED, _PENDING, _REJECTED } from '../actions/types';
const initialState = {
    list: [],
    isFetching: false,
    isPending: false,
    error: null,
};

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case inmCaractTypes.MOSTRAR_CARACTERISTICAS + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case inmCaractTypes.MOSTRAR_CARACTERISTICAS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
            }
        }
        case inmCaractTypes.MOSTRAR_CARACTERISTICAS + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        //agregar  o editar

        case inmCaractTypes.AGREGAR_EDITAR_CARACTERISTICA + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case inmCaractTypes.AGREGAR_EDITAR_CARACTERISTICA + _FULFILLED: {
            const { data } = action.payload;
            var list = state.list.filter(e => e.carId !== data.carId);
            list.unshift(data);

            return {
                ...state,
                list,
                isPending: false,
                error: {}
            }
        }
        case inmCaractTypes.AGREGAR_EDITAR_CARACTERISTICA + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }
        //Eliminar

        case inmCaractTypes.ELIMINAR_CARACTERISTICA + _FULFILLED: {
            const { data: id  } = action.payload;
            let list = state.list.filter(e => e.carId !== id);
            return {
                ...state,
                list,
                isPending: false,
                error: {}
            }
        }
        case inmCaractTypes.ELIMINAR_CARACTERISTICA + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }

        default:
            return state;
    }
}