import React from 'react';
import {makeStyles} from "@material-ui/core";
import Strings from "../assets/strings";

const useStyles = makeStyles((theme) => ({
    emptyCont:{
        margin:"0 auto",
        maxWidth:800,
        padding:theme.spacing(1),
        paddingTop:"7%",
        textAlign:"center",
        "& p":{
            marginTop:0,
        }
    }
}));


export default function Empty({Img,title =Strings.aunNoTienes+" "+Strings.registros,children}) {
    const classes = useStyles();
    return (
        <div className={classes.emptyCont}>
            {Img &&
                <Img width={140} height={140}/>
            }
            <p>{title}</p>
            {!!children && children}
        </div>
    );
}