import React, {useState} from 'react';
import { connect } from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {agregarNota,eliminarNota} from "../../redux/actions/contratoActions"
import {useHistory} from "react-router-dom";
import Strings from "../../assets/strings";

import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  IconButton,
  Divider,
  useMediaQuery,
} from '@material-ui/core';
import {formatDate, hasPermissionTo, PERSMISOS} from "../../helpers";
import {LoadingProgress,TopBarDialog} from "../../components";
import {Close} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  content:{
    padding: theme.spacing(2),
    "& p":{
      margin: "2px 0px 8px",
    }
  },
  contTextArea:{
    position: "relative",

    "& textarea":{
      padding: theme.spacing(1)+"px "+theme.spacing(2)+"px",

      display: "block",
      width: "100%",
      resize: "none",
      border: "none",
      outline: "none",
      boxSizing: "border-box",
      height: 33,
      transition: "all 100ms",
    },

    "& .addCont":{
      display: "flex",
      justifyContent: "flex-end",
      padding: "0px 16px",
    }
  },
  item:{
    position: "relative",
    borderBottom: "1px solid #c9c9c9",
    marginBottom: 8,
    "&:hover button":{
      display:"block",
    },
    "& button":{
      display:"none",
      position: "absolute",
      right: 0,
    }
  }
}));
function NotasInmueble({notas,isPending,conId,...props}){
  let history = useHistory();
  const classes = useStyles();
  const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('sm'));
  const [state, setState] = useState({ctnNota:""});

  const handleChange = e => {
    const {value,name} = e.target;
    setState({...state,[name]:value});
  };
  const submit = e => {
    props.agregarNota({...state,conId});
    setState({ctnNota:""});
  };
  return (
      <Dialog fullScreen={smVewport} open={true} fullWidth maxWidth={"xs"} onClose={()=>history.goBack()}>
        <TopBarDialog title={Strings.notas} onClose={()=>history.goBack()} nofullScreen/>
        <DialogContent className={classes.content}>
          {isPending && <LoadingProgress vh={false} />}
          {notas && notas.map(nota=>
              <div key={nota.ctnId} className={classes.item} >
                {hasPermissionTo(PERSMISOS.contratosEliminarNotas) &&
                  <IconButton onClick={()=>props.eliminarNota(nota.ctnId)} size={"small"}><Close fontSize={"small"} className={"text-red"}/></IconButton>
                }
                <Typography variant={"caption"}>{formatDate(nota.ctnFecha)}</Typography>
                <p>{nota.ctnNota}</p>
              </div>
          )}
        </DialogContent>
        {state.ctnNota && <Divider/>}
        {hasPermissionTo(PERSMISOS.contratosCrearNotas) &&
          <div className={classes.contTextArea}>
            <textarea placeholder={Strings.agregar+" "+Strings.notas} value={state.ctnNota} name="ctnNota"
                      onChange={handleChange}
                     maxLength="256"
            />

            {state.ctnNota &&
            <div className="addCont">
              <Button size={"small"} color={"primary"} onClick={submit}>{Strings.agregar}</Button>
            </div>
            }
          </div>
        }
      </Dialog>
  );
}
const mapStateToprops = (state) => ({
  conId:state.contratos.byId.conId,
  notas:state.contratos.byId.notas || [],
  isPending: state.contratos.isPending,
});
export default connect(mapStateToprops,{agregarNota,eliminarNota})(NotasInmueble);

