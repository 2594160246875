import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, IconButton,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TextField,Box,Typography
} from '@material-ui/core';
import {Close} from "@material-ui/icons";
import Strings from "../assets/strings";
import {TextFieldPhone} from "./CustomTextField";
import TopBarDialog from "./topBar/TopBarDialog";
import {MostrarError} from "./MostrarError";
import SelectUsoMultiples from "./selects/SelectUsoMultiples";



const initialState={
    refTipo:"",
    refNombres:"",
    refTelefono:"",
    refRelacion:""
};
export default  function ReferenciasCliente({referencias=[], onChange}){

    const [state, setState] = useState(initialState);
    const [error, setError] = useState(null);

    
    const [openNewRef, setOpenNewRef] = useState(false);
    const handleChange = e => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };
    const deleteRef = (refId) =>{
        let refs = [...referencias];


        for (var i = 0; i < refs.length; i++) {
            if (refs[i].id === refId) {
                refs.splice(i, 1);
                onChange(refs);
            }
        }
    };
    const addRef = (e)=>{
        e.preventDefault();
        setError(null);
        let refs = [...referencias];
        let newRef = state;

        if(state.refTipo && state.refNombres && state.refTelefono && state.refRelacion){
            newRef.id = Math.random();
            refs.unshift(newRef);
            onChange(refs);
            setOpenNewRef(false);
            setState(initialState)
        }else {
            setError(Strings.completeCamposRequeridosMsj)
        }
    };

    return (
        <>
            <div>
                <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"0 5px"}>
                    <Typography style={{flex: '1 1 100%'}} variant="body1" color={"secondary"}>
                        {Strings.referencias}
                    </Typography>
                    <Button color={"primary"} onClick={()=>setOpenNewRef(!openNewRef)}>
                        {Strings.agregar}
                    </Button>
                </Box>
                {!!referencias.length &&
                    <TableContainer>
                        <Table aria-label="simple table" size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>{Strings.tipo}</TableCell>
                                    <TableCell>{Strings.nombreCompleto}</TableCell>
                                    <TableCell>{Strings.telefono}</TableCell>
                                    <TableCell>{Strings.parentesco}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {referencias.map((ref,i)=>{
                                    return(
                                        <TableRow key={i+1}>
                                            <TableCell className="actionCell">
                                                <IconButton onClick={()=>deleteRef(ref.id)}>
                                                    <Close className="text-red"/>
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <SelectUsoMultiples group="RefTipo" value={ref.refTipo} />
                                            </TableCell>
                                            <TableCell>{ref.refNombres}</TableCell>
                                            <TableCell>{ref.refTelefono}</TableCell>
                                            <TableCell>
                                                <SelectUsoMultiples  group="RefTipo" value={ref.refRelacion} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </div>
            <Dialog maxWidth="xs" onClose={()=>setOpenNewRef(false)} open={!!openNewRef}>
                <TopBarDialog title={Strings.agregar+" "+Strings.referencia} onClose={()=>setOpenNewRef(false)} nofullScreen/>
                <DialogContent>
                        <MostrarError errors={error} onClose={()=>setError(null)}/>

                    <SelectUsoMultiples name="refTipo" value={state.refTipo} label={Strings.tipo} group="RefTipo" onChange={handleChange} required />

                    <TextField
                        name="refNombres"
                        label={Strings.nombreCompleto}
                        value={state.refNombres}
                        onChange={handleChange}
                        fullWidth
                        margin="dense"
                        required
                    />
                    <TextFieldPhone
                        name="refTelefono"
                        label={Strings.telefono}
                        value={state.refTelefono}
                        onChange={handleChange}
                        required
                        margin="dense"
                        fullWidth
                    />
                    <SelectUsoMultiples name="refRelacion" value={state.refRelacion} label={Strings.parentesco} group="refRelacion" onChange={handleChange} required />

                </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>setOpenNewRef(false)} color="primary">{Strings.cancelar}</Button>
                        <Button color="primary" onClick={addRef}>{Strings.agregar}</Button>
                    </DialogActions>
            </Dialog>
        </>
    );
}
