import React from 'react';
import {
    makeStyles,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel
} from "@material-ui/core";
export default function TableSort({data,onSort, children,stickyFCol=false,size,lessVh}){

    const useStyles = makeStyles(theme => ({
        ...(lessVh?{
            root:{
                maxHeight: "calc(100vh - "+lessVh+"px)"
            },
            [theme.breakpoints.down('sm')]: {
                root:{
                    maxHeight: "calc(100vh - "+(lessVh-85)+"px)"
                },
            },
        }:{}),
        stickyFCol:{
            "& tbody tr td:first-child, & thead tr th:first-child ":{
                position: 'sticky',
                background: "inherit",
                left: 0,
                // zIndex: 1,
            },
            "& thead tr th:first-child ":{
                background: "#fafafa",
            }
        },
        tableCell: {
            "& *":{
                color: theme.palette.primary.light + " !important"
            }
        },
    }));
    const classes = useStyles();
    return (
        <>
            <TableContainer className={classes.root+" "+(stickyFCol?classes.stickyFCol:"")}>
                <Table className="hasOnclick" size={size} stickyHeader >
                    <TableHead >
                        <TableRow>
                            {data.map((item,i) =>
                                <TableCell key={i} className={classes.tableCell}>
                                    {!item.pro?(<>{item.text}</>):(
                                        <TableSortLabel {...onSort(item.pro)}>{item.text}</TableSortLabel>
                                    )}
                                </TableCell>)}
                        </TableRow>
                    </TableHead>
                    {children}
                </Table>
            </TableContainer>
        </>
    );
}
