import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import { PayPalButton } from "react-paypal-button-v2";

import {mostrarPerfil, setCurrentUser} from '../../redux/actions/authentication';
import {
    Typography,
    Button,
    CircularProgress,
    TextField,
    Box,
    makeStyles,
    Divider,
    Grid
} from '@material-ui/core';
import Strings from "../../assets/strings";
import {getLSLang} from "../../helpers";
import {getGeo, PostRegister} from "../../services";
import {connect} from "react-redux";
import styles from "../../assets/jss/login";
import Alert from "@material-ui/lab/Alert";
import {MostrarError} from "../../components";
import {WhatsApp, YouTube} from "@material-ui/icons";
import {TextFieldPhone} from "../../components/CustomTextField";
import LoadingProgress from "../../components/LoadingProgress";
import {AlertTitle} from "@material-ui/lab";
const useStyles = makeStyles(styles);


function Register({mostrarPerfil,setCurrentUser }){
    const classes = useStyles();
    const [passFocus, setPassFocus] = useState(false)

    const [error, setError] = useState(true)
    const [isLoading, setLoading] = useState(false)
    const [step, setStep] = useState(-1); //-1
    const [state, setState] = useState({
        lang:getLSLang("l"),
        usuInicioSesion: "",
        usuClave: "",
        usuNombres: "",
        usuApellidos: "",
        usuEmail: "",
        usuTelefono: "",
    });

    useEffect(()=>{
        getGeo().then(r=>{
            setCurrentUser({genLocalidad: r?.data?.country_code ?? "DO"})
        }).catch(()=>setCurrentUser({genLocalidad: "DO"}))
    },[setCurrentUser])
    const handleChange = (e)=>{
        const {name, value} = e.target
        setState({...state, [name]: value});

        if(error) setError(null)
    }
    const submitForm = (obj,validate ) =>{
        setLoading(true)
        PostRegister({...state,...obj,lang:null},validate)
            .then((response) =>{
                if(validate){
                    setStep(2);
                }else {
                    setStep(3);
                    localStorage.setItem("_token", response.data.token);
                    mostrarPerfil(response.data);
                }
            })
            .catch(({response}) => {
                setError(response.data);
            }).finally(()=>setLoading(false));
    }

    return(
        <div className={classes.root}>
            {step === -1 ?(
                <div className={classes.video}>
                    <iframe width="100%" height="100%" title={"video Promo"}  src="https://www.youtube.com/embed/8rtNmQL_KlE" frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen/>
                    <div>
                        <Button component={Link} color={"secondary"} to={"/login"}>{Strings.cancelar}</Button>
                        <Button color={"primary"} variant={"contained"} disableElevation onClick={()=>setStep(1)}>{Strings.registrarme}</Button>
                    </div>
                </div>
                ):(
                    <>
                        <div className={classes.logo}>
                            <Typography variant="h1"  color="primary">GestInmueble</Typography>
                            <Typography variant="h3" >{Strings.slogan}</Typography>
                        </div>
                        {step !== 3 && <Typography  className={classes.headline}>{Strings.crearNuevaCuenta}</Typography>}

                        <div className={classes.form}>
                            {!!error &&
                                <MostrarError errors={error}/>
                            }
                            {isLoading && <LoadingProgress/>}
                            {step === 3 &&
                            <div>
                                <Alert severity="success">Registro finalizado con éxito.</Alert>
                                <p>Hola {state.usuNombres+" "+state.usuApellidos}, a continuación le dejo algunos enlaces que pueden ser de su interés. Cualquier información adicional, por favor comunicase con nosotros.</p>
                                <Box mt={1} mb={1}><Divider/></Box>
                                <Button
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    startIcon={<YouTube />}
                                    target={"_blank"}
                                    rel="noreferrer"
                                    href={"https://www.youtube.com/channel/UCb1PHnAm3IZs55qQU9pVAcA"}
                                    fullWidth
                                >
                                    Video Tutoriales
                                </Button>
                                <Button
                                    color="primary"
                                    size="small"
                                    className={classes.button}
                                    startIcon={<WhatsApp />}
                                    target={"_blank"}
                                    rel="noreferrer"
                                    href={"https://wa.me/18498641680"}
                                    fullWidth
                                >
                                    Chat vía WhatApp
                                </Button>
                                <Box mt={1}>
                                    <Button variant="contained" color="primary" disableElevation fullWidth component={Link} to="/inicio">
                                        {Strings.iniciarSesion}
                                    </Button>
                                </Box>

                            </div>
                            }
                            {step === 1 &&
                            <form id="form" onSubmit={e=>{e.preventDefault();submitForm({},false)}}>
                                <Box  color="text.secondary">{Strings.datosPersonales}</Box>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant={"outlined"}
                                            label={Strings.primerNombre}
                                            required
                                            margin="dense"
                                            name="usuNombres"
                                            value={state.usuNombres}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant={"outlined"}
                                            label={Strings.apellidos}
                                            required
                                            margin="dense"
                                            name="usuApellidos"
                                            value={state.usuApellidos}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant={"outlined"}
                                            label={Strings.email}
                                            type="mail"
                                            required
                                            margin="dense"
                                            name="usuEmail"
                                            value={state.usuEmail}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextFieldPhone
                                            flag
                                            variant={"outlined"}
                                            label={Strings.telefono}
                                            type="mail"
                                            required
                                            margin="dense"
                                            name="usuTelefono"
                                            value={state.usuTelefono}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Box color="text.secondary" width={"100%"}>{Strings.datosParaIniciarSesion}</Box>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant={"outlined"}
                                            label={Strings.nombreDeUsuario}
                                            required
                                            margin="dense"
                                            name="usuInicioSesion"
                                            value={state.usuInicioSesion}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            variant={"outlined"}
                                            label={Strings.contrasena}
                                            required
                                            margin="dense"
                                            name="usuClave"
                                            onFocus={()=>setPassFocus(true)}
                                            onBlur={()=>setPassFocus(false)}
                                            type={passFocus?"text":"password"}
                                            value={state.usuClave}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            fullWidth
                                        />
                                    </Grid>
                                    {/*<div>*/}
                                    {/*    <FormControlLabel style={{marginRight: 5}}*/}
                                    {/*                      control={<Checkbox required color="primary"/>}*/}
                                    {/*                      label={Strings.aceptar}*/}
                                    {/*    />*/}
                                    {/*    <a href="https://prestamistapp.com/FrontPage/TerminosYcondiciones"*/}
                                    {/*       target={"_blank"} rel="noopener noreferrer">{Strings.terminosYcondiciones}.</a>*/}
                                    {/*</div>*/}
                                </Grid>
                                <Box display="flex" justifyContent="space-between" mt={2}>
                                    <Button type="submit" form="form" fullWidth variant="contained" color="primary" disableElevation disabled={isLoading}>
                                        {isLoading? (
                                            <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                        ):(
                                            <>{Strings.finalizar}</>
                                        )}
                                    </Button>
                                </Box>
                            </form>
                            }

                            {step === 2 &&
                                <>
                                    {/*<PlanSubscription onApprove={obj=>submitForm(obj)}/>*/}
                                    {error &&
                                    <>
                                        <Alert severity="error" onClose={()=>setError(false)}>
                                            <AlertTitle>Error</AlertTitle>
                                            Contáctanos vía <a href="https://wa.me/18498641680">WhatsApp</a>.
                                        </Alert><br/>
                                    </>
                                    }
                                    <PayPalButton
                                        amount={5}
                                        shippingPreference={"NO_SHIPPING"}
                                        onSuccess={(details) => {
                                            // let cliInfo = {
                                            //     usuNombres: details?.payer?.name?.given_name,
                                            //     usuApellidos: details?.payer?.name?.surname,
                                            //     usuEmail: details?.payer?.email_address,
                                            //     usuOrderId: details?.purchase_units[0]?.payments?.captures[0]?.id
                                            // };

                                            let usuOrderId = details?.purchase_units[0]?.payments?.captures[0]?.id

                                            return submitForm({usuOrderId});

                                        }}
                                        onError={()=>setError(true)}
                                        options={{
                                            clientId: "AWBEotIk0ZckSFeHQQLDYKVF7yDY8YUq5wBNJJ3E40SZ1lkx9in0Fjmnrzme59aPR_zlwIEgxES5_H7d"
                                           //hotmail clientId: "AQmh-AVJifRjxrsvuvRcph8oso0ZZB9qk8yNBe_A6jnf2eiE9meItEUl6TGbXN2DKTDqVDm6CyzY_Y6t",
                                        }}
                                        style={{
                                            // tagline:true,
                                            shape: 'rect',
                                            color: 'blue',
                                            layout: 'horizontal',
                                            label: 'pay',
                                        }}
                                    />
                                    <Typography variant={"body2"} align={"center"}>UN MES ILIMITADO por solo $5, luego adquieres el plan de tu preferencia.</Typography>
                                </>
                            }
                        </div>
                        <Typography className={classes.bmLink}>{Strings.yaCuenta} <Link to="/login">{Strings.iniciarSesion}</Link></Typography>
                        {!!error &&
                        <Button className={classes.moreInfo}
                                component={"a"} variant="contained"
                                disableElevation color="default"
                                href={"https://gestinmueble.com/?p=true"}
                        >
                            Información del Sistema
                        </Button>
                        }
                    </>
                )

            }
        </div>
    )
}
export default connect(null, {mostrarPerfil,setCurrentUser })(Register);
