import { mensajeTypes, _FULFILLED, _PENDING, _REJECTED } from '../actions/types';
const initialState = {
    list: [],
    isFetching: false,
    error: null,
};

const index = (state = initialState, action={}) => {

    switch (action.type) {
        case mensajeTypes.MOSTRAR_MENSAJES + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case mensajeTypes.MOSTRAR_MENSAJES + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
                error: {}
            }
        }
        case mensajeTypes.MOSTRAR_MENSAJES + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        case mensajeTypes.MARCAR_MENSAJE_LEIDO + _FULFILLED: {
            const { data: menId } = action.payload;
            let list = state.list;
            if(list){
                list.forEach(e=>{
                    if(e.menId === menId){
                        e.menLeido = true;
                    }
                })
            }
            return {
                ...state,
                list
            }
        }
        default:
            return state;
    }
}

export default index;