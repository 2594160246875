import React, {useEffect, useState} from "react";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {moment, noTilde, openNewWin} from "../../helpers";
import {FormControl, Grid, InputLabel, makeStyles, TextField, Typography, Button, useMediaQuery} from '@material-ui/core';
import {connect} from "react-redux";
import {mostrarCxC} from "../../redux/actions/dashBoardActions"
import {useHistory} from "react-router-dom";
import {EventOutlined, FilterList,Search,Close} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import ItemContrato from "../CustomListItem/ItemContrato";
import {SelectSmart} from "../CustomTextField";
import HtmlTooltip from "../HtmlTooltip";
import {ReactComponent as EmptySvg} from '../../assets/img/empty.svg';
import {List,AutoSizer} from 'react-virtualized';
import Strings from "../../assets/strings";

const useStyles = makeStyles(theme => ({
    root:{
        background: theme.palette.common.white,
        border: "1px solid " +theme.palette.grey[300],
        position: "relative",

        "& .inputSearch":{
            position: "absolute",
            padding: "4px 22px 2px 8px",
            top: "10px",
            left: "9px",
            width: "calc(100% - 116px)"
        }
    },
    content:{
        height: 464,
        overflow: "auto",
        //overflowX:"hidden",
        [theme.breakpoints.up('md')]: {
            overflow: "hidden",
            "&:hover":{
                overflow: "overlay",

            }
        },
    },
    filterButton: {
        //margin: theme.spacing.unit,
        padding: "2px 5px",
        minHeight: "auto",
        minWidth: 104,
        marginLeft: 4,
        "& svg":{
            width:18,
        }
    },
    head:{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "10px "+theme.spacing(1)+"px",
        borderBottom: "1px solid "+theme.palette.grey[300],
        "& .title":{
            marginRight: "auto",
        },
        "& .MuiIconButton-root":{
            marginLeft:theme.spacing(1),
        }
    },
    filterCont:{
        background: theme.palette.grey[100],
        padding: theme.spacing(1),
        "& label":{
            fontSize:".8rem"
        },
        "& .MuiInput-root":{
            "& input,& select, & .MuiSelect-root":{
                padding: "4px 5px",
                fontSize: ".9rem",
            }
        }

    },
    filterControls:{
        display: "flex",
        // [theme.breakpoints.up('md')]:{
        //     marginTop: 23,
        // },
        [theme.breakpoints.only('sm')]:{
            marginTop: 8,
        },
        "& button":{
            fontSize: ".75rem",
            width:"100%",
            background:theme.palette.grey[200],
            marginLeft:2,
            height: 36,
            padding: "5px 8px"
        }
    },
    clr:{
        height: 296,
        overflow: "hidden",
        "& .MuiPickersCalendarHeader-switchHeader":{
            padding: 6,
            "& button":{
                padding: 0,
            },
            "&>div":{
                marginTop: 0,
                textTransform: "capitalize",
            },
        },
        "& .MuiPickersBasePicker-pickerView":{
            width: "100% !important",
            maxWidth: "100% !important",
        },
        "& .MuiPickersCalendarHeader-dayLabel":{
            width: "13% !important",
            textTransform: "capitalize",
            color: theme.palette.common.black,
        },
        "& .MuiPickersCalendar-transitionContainer":{
            minHeight: "242px !important"
        },
        "& .MuiPickersDay-day":{
            width: 28,
            height: 28,
            marginRight: 5,
            marginTop: 3,
        },
        "& .highlight .MuiPickersDay-day":{
            background: "#d4e8ff",
            color:theme.palette.common.black,
        },

        "& .MuiPickersSlideTransition-transitionContainer":{
            marginTop: theme.spacing(1),
        },
        "& .MuiPickersCalendar-week":{
            "&>div":{
                textAlign: "right",
                border: "1px solid "+theme.palette.grey[100],
                width: "13.5%",
                height: 33,
            }
        }
    },
    emptyCont:{
        paddingTop:"15%",
        textAlign:"center",
        opacity: .7,
        "&:hover":{
            opacity: 1,
        },
        "& p":{
            marginTop:0,
            color: theme.palette.text.hint,
        }
    },
    btnShowAll:{
        position: "absolute",
        bottom: 0,
        background: theme.palette.grey[200],
        opacity: .6,
        "&:hover":{
            opacity: 1,
        },
    }
}));
const initialState={
    cliente:"",
    estado: "",
    fechaDesde: "",
    fechaHasta: "",
    fechaProximoPago:"",
};

function CxC({list,mostrarCxC,loading}) {
    let history = useHistory();
    const xxsVewport = useMediaQuery((theme)=>theme.breakpoints.down(400));

    const classes = useStyles();
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [showSearch, setShowSearch] = useState(false);

    const [showCal, setShowCal] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
    const [filter, setFilter] = useState(initialState);
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        if(list){
            setFilteredList(filterCxCList(list,filter))
        }
    }, [filter,list,setFilteredList])
    const handleFilter = e => {
        const {name, value} = e.target;
        setFilter({...filter,[name]:value})
        if(name === "estado" && value === "incluirCxcAldia"){
            mostrarCxC({incluirCxcAldia:true})
        }
    };
    const handleCalChange = (day) => {
        const listInclude =  list.find(p=>moment(p.fechaProximoPago).isSame(day,"day"));
        if(!listInclude) return false;
        setShowCal(false);
        setSelectedDay(day)
        setFilter({...filter,fechaProximoPago: day.format("YYYY-MM-DD")})
    };
    return (
        <>
            <div className={classes.root}>
                {showSearch &&
                    <input type="text" name="cliente" className="inputSearch" autoFocus value={filter.cliente} onChange={handleFilter}/>
                }
                <div className={classes.head}>
                    <Typography className="title">{Strings.cuentasXcobrar}</Typography>

                    <HtmlTooltip title={Strings.buscar+" "+Strings.por+" "+Strings.nombres} placement={"top"}>
                        <IconButton size={"small"} onClick={()=>setShowSearch(!showSearch)}>
                            {showSearch ? (<Close fontSize={"small"}/>):(<Search fontSize={"small"}/>)}</IconButton>
                    </HtmlTooltip>

                    <HtmlTooltip title={Strings.filtrar} placement={"top"}>
                        <IconButton size={"small"} color={showFilter?"primary":"default"}
                                onClick={()=>setShowFilter(!showFilter)}> <FilterList fontSize={"small"}/></IconButton>
                    </HtmlTooltip>
                    <HtmlTooltip title={Strings.proximo+" "+Strings.pago} placement={"top"}>
                        <IconButton size={"small"} color={showCal?"primary":"default"}
                                    onClick={()=>setShowCal(!showCal)}> <EventOutlined fontSize={"small"}/></IconButton>
                    </HtmlTooltip>
                </div>
                <div className={classes.content}>
                    {showFilter &&
                        <div className={classes.filterCont}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={4}>
                                    <TextField
                                        label={Strings.fecha+" "+Strings.desde}
                                        type="date"
                                        value={filter.fechaDesde}
                                        onChange={handleFilter}
                                        name="fechaDesde"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} md={4}>
                                    <TextField
                                        label={Strings.fecha+" "+Strings.hasta}
                                        type="date"
                                        value={filter.fechaHasta}
                                        onChange={handleFilter}
                                        name="fechaHasta"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink>{Strings.estado}</InputLabel>
                                        <SelectSmart
                                            value={filter.estado}
                                            onChange={handleFilter}
                                            name="estado"
                                            opciones={[
                                                {key: '', value: Strings.todo},
                                                {key: 'pendiente', value: Strings.pendiente},
                                                {key: 'cpendientes ', value: Strings.cuotaVencida},
                                                {key: 'mora', value: Strings.mora},
                                                {key: 'incluirCxcAldia', value: Strings.incluir+" "+Strings.aldia},
                                            ]}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.filterControls}>
                                        <Button onClick={()=>{setShowFilter(false);
                                                              setShowCal(false);
                                                              setFilter(initialState)}}>{Strings.todo}</Button>
                                        <Button onClick={()=>openNewWin("/reports/cxc",filter)}>{Strings.exportar}</Button>
                                    </div>
                                </Grid>
                            </Grid>

                        </div>
                    }
                    {showCal?(
                        <div className={classes.clr}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    variant="static"
                                    disableToolbar
                                    value={selectedDay}
                                    onChange={handleCalChange}
                                    renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
                                        let listInclude = list.find(p=>moment(p.fechaProximoPago).isSame(day,"day"));
                                        return <div className={listInclude ? "highlight" : "default"}>{dayComponent}</div>;
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        ):(
                        <div className="items">
                            {filteredList.length >0 ?(
                                <AutoSizer>
                                    {({width }) => (
                                    <List
                                        width={width-1}
                                        height={464}
                                        rowCount={filteredList.length}
                                        rowHeight={xxsVewport? 137:99}
                                        rowRenderer={({key, index, isScrolling, isVisible, style,}) =>(
                                            <div key={key} style={style}>
                                                <ItemContrato onClick={()=>history.push(`/contrato/${filteredList[index].conId}`)} item={filteredList[index]}/>
                                            </div>
                                        )}
                                    />
                                )}
                                </AutoSizer>
                                // filteredList.map(con =>
                                //     <ItemContrato key={con.conId} onClick={()=>history.push(`/contrato/${con.conId}`)} item={con}/>
                                // )
                            ):(
                                !loading &&
                                <div className={classes.emptyCont}>
                                    <EmptySvg width={140} height={140}/>
                                    {list.length > 0 ?(
                                        <p>{Strings.emptyMsj}</p>
                                    ):(
                                        <p>{Strings.noCuentasXcobrarMjs}</p>
                                    )}
                                </div>
                            )}
                        </div>
                        )}
                    {filteredList.length !== list.length &&
                    <Button fullWidth size={"small"} className={classes.btnShowAll} onClick={()=>setFilter(initialState)}>
                        {Strings.mostrar+" "+Strings.todo}
                    </Button>
                    }
                </div>
            </div>
        </>
    )
}
export function filterCxCList(list = [], filter  = {}){
    let f = {...initialState,...filter};
    const {cliente, estado: est, fechaDesde, fechaHasta, fechaProximoPago} = f;
    let estado = est === "incluirCxcAldia"? "" : est;

    let filtered = [];
    let fDesde = moment(fechaDesde).isValid() ? fechaDesde : moment().subtract(5, 'years');
    let fHasta = moment(fechaHasta).isValid() ? fechaHasta : moment().add(5, 'years');
    let fProximoPago = moment(fechaProximoPago).isValid() ? fechaProximoPago : "";


    list.forEach(item => {
        if (
            noTilde(item.cliNombre).indexOf(noTilde(cliente)) !== -1 &&
            (item.estado === estado || estado === "") &&
            (fProximoPago === "" || moment(item.fechaProximoPago).isSame(fProximoPago,"day")) &&
            moment(item.fechaProximoPago).isBetween(fDesde, fHasta)
        ) {
            filtered.push(item);
        }
    });
    return filtered;
}

const mapStateToProps = (state) => ({
    list: state.dashBoard?.cxc || [],
    loading: state.dashBoard.isFetching,
    viewTableList: state.theme.viewTableList
})
export default connect(mapStateToProps,{mostrarCxC})(CxC);