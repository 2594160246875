import React, {useState} from 'react';
import {
    useHistory,
    useLocation
} from 'react-router-dom'
import {CircularProgress, Typography} from '@material-ui/core';

import {TextField,InputAdornment,Button} from '@material-ui/core';
import {EmailOutlined} from '@material-ui/icons'

import styles from "../../assets/jss/login";
import {makeStyles} from "@material-ui/core/styles";
import Strings from "../../assets/strings";
import {Link} from "react-router-dom";
import {forgotPassword, forgotPasswordConfirm} from "../../services";
import {MostrarError} from "../../components";
import Alert from "@material-ui/lab/Alert";
const useStyles = makeStyles(styles);

function ForgotPassword(){
    const {search} = useLocation()
    const code = search ? search.replace("?code=",""):"";
    let history = useHistory();

    const classes = useStyles();
    const [sentCode, setSentCode] = useState(false)

    const [error, setError] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [state, setState] = useState({
        usuEmail: "",
        newPassword: "",
    })

    const handleChange = (e)=>{
        const {name, value} = e.target
        setState({...state, [name]: value});
    }

    const submitForm = (e) =>{
        e.preventDefault()
        setLoading(true)

        const request = code ? forgotPasswordConfirm({code:code,newPassword:state.newPassword}): forgotPassword({usuEmail:state.usuEmail});

        request.then(() =>{
            code ? history.replace("/login") :setSentCode(true)
        }).catch(({response}) => {
            setError(response.data)
        }).finally(() => {
            setLoading(false)
        });
    }
    const validatePassword = ()=>{
        let confirm_password = document.getElementById("confirm_password");
        if(state.newPassword !== confirm_password.value) {
            confirm_password.setCustomValidity(Strings.passNoMatch);
        } else {
            confirm_password.setCustomValidity('');
        }
    }

        return(
            <div className={classes.root}>
                <div className={classes.logo}>
                    <Typography variant="h1"  color="primary">GestInmueble</Typography>
                    <Typography variant="h3" >{Strings.slogan}</Typography>
                </div>
                <div className={classes.form}>
                    {sentCode ? (
                        <Alert severity="success">{Strings.contrasenaEnviadaMsj}</Alert>
                    ):(
                        <form onSubmit={submitForm}>
                            <Typography  className={classes.headline}>{Strings.recuperarContrasena}</Typography>
                            {!!error &&
                            <MostrarError errors={error}/>
                            }
                            <div>
                                {!code &&
                                <TextField
                                    value={state.usuEmail}
                                    onChange={handleChange}
                                    name={"usuEmail"}
                                    label={Strings.email}
                                    margin="normal"
                                    required
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <EmailOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                                {code &&
                                <>
                                    <TextField
                                        value={state.newPassword}
                                        onChange={handleChange}
                                        name={"newPassword"}
                                        label={Strings.contrasenaNueva}
                                        margin="normal"
                                        required
                                        fullWidth
                                    />
                                    <TextField
                                        id="confirm_password"
                                        onChange={handleChange}
                                        label={Strings.confirmar+" "+Strings.contrasena}
                                        margin="normal"
                                        required
                                        onBlur={validatePassword}
                                        fullWidth
                                    />
                                </>
                                }
                            </div>
                            <Button type="submit" variant="contained" className={classes.submitBtn} color="primary" disableElevation disabled={isLoading}>
                                {isLoading? (
                                    <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                ):(
                                    <>{Strings.enviar}</>
                                )}
                            </Button>
                            {code &&
                            <Typography className={classes.bmLink} style={{marginTop: 9, textAlign: "center"}}>
                                <Link to="/forgotPassword" > {Strings.enviar} {Strings.nuevo}</Link>
                            </Typography>
                            }
                        </form>
                    )}
                </div>
                <Typography className={classes.bmLink}><Link to="/login">{Strings.iniciarSesion}</Link></Typography>
            </div>
        )
}
export default ForgotPassword;