import React, {Fragment, useEffect} from 'react';
import { connect } from 'react-redux';
import { mostrarUsosMultiples, eliminarUsoMultiple } from '../../redux/actions/usosMultiplesActions';
import AppBar from "../../components/appBar";
import {Button, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@material-ui/core";
import {formatDate} from "../../helpers";
import TableHead from "@material-ui/core/TableHead";
import {Close, Edit} from "@material-ui/icons";
import {useHistory, useLocation} from "react-router-dom";
import Crear from "./crear";
import PlusFab from "../../components/PlusFab";
import ConfirmDialog from "../../components/ConfirmDialog";
import Strings from "../../assets/strings";
import _ from "lodash";

function UsosMultiples(props) {
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();


    const { mostrarUsosMultiples } = props;
    useEffect(() => {
        mostrarUsosMultiples();
    }, [mostrarUsosMultiples]);

    const { usosMultiples: { list, isFetching} } = props;
    const isEmpty = !isFetching && Object.keys(list).length === 0;

    return (
        <>
            <AppBar title={"Usos Multiples"}/>
            <section className="contentInner">
                <div className="table-responsive">
                    {isFetching && !list.length &&
                    <div className="loading"/>
                    }

                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>Código</TableCell>
                                    <TableCell>Descripción</TableCell>
                                    <TableCell>Usuario</TableCell>
                                    <TableCell>Creación</TableCell>
                                    <TableCell>Actualizado</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!isEmpty && Object.keys(list).map((property,i) =>
                                    <Fragment key={i}>
                                        <TableRow>
                                            <TableCell colSpan={6}><Typography variant={"body1"} color={"primary"}>{property}</Typography> </TableCell>
                                        </TableRow>
                                        {list[property].map((item,i)=>
                                            <TableRow key={i}>
                                                <TableCell className={"actionCell"}>
                                                    <IconButton  onClick={()=> history.push(history.location.pathname,item)} >
                                                        <Edit/>
                                                    </IconButton>
                                                    <IconButton onClick={()=>history.push(history.location.pathname,
                                                        {confirmDialog:{usoGrupo:item.usoGrupo,usoCodigo:item.usoCodigo }})} >
                                                        <Close className={"text-red"}/>
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>{item.usoCodigo}</TableCell>
                                                <TableCell>{Strings[_.camelCase(item.usoDescripcion)] || <span className="text-red">{item.usoDescripcion}</span> }</TableCell>
                                                <TableCell> {item.usuario}</TableCell>
                                                <TableCell> {formatDate(item.usoFechaCreacion)}</TableCell>
                                                <TableCell> {formatDate(item.usoFechaActualizacion)}</TableCell>

                                                {/*<TableCell> "{_.camelCase(item.usoDescripcion)}" :"{item.usoDescripcion}",</TableCell>*/}

                                            </TableRow>
                                        )}
                                    </Fragment>
                                )}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    {isEmpty && <div className="isEmpty">No se encontraron registros</div>}
                </div>
                <PlusFab component={Button} onClick={()=>history.push(history.location.pathname,{usoCodigo:null})} />

            </section>
            <Crear/>
            <ConfirmDialog onConfirm={()=>props.eliminarUsoMultiple(urlState && urlState.confirmDialog)}/>

        </>
    );

}

const mapStateToProps = (state) => ({
    usosMultiples: state.usosMultiples
});
export default connect(mapStateToProps, { mostrarUsosMultiples, eliminarUsoMultiple })(UsosMultiples);
