import React, {Fragment, useState} from 'react';
import {anularPago} from "../redux/actions/pagosActions"
import {
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import Strings from "../assets/strings";

import {Close} from "@material-ui/icons";
import {formatDate, formatPeso, hasPermissionTo, PERSMISOS} from '../helpers';

import {switchControl} from "../assets/jss";
import ConfirmDialog from "./ConfirmDialog";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import ReciboPago from "./recibo";

const useStyles = makeStyles(theme => ({
  ...switchControl,
  barTitle:{
    padding: "6px 12px",
    background: theme.palette.grey[100],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    '& > label':{
      marginRight: 0
    }
  },
  pagAnulado:{
    background: "#fff1f1",
    "& td, th":{
      color:theme.palette.error.light,
    }
  },
  print: {
    color: theme.palette.primary.main,
  },
  pagAjuste:{
    background:"#d6f4ff !important"
  }
}));


function Pagos(props){
  let history = useHistory();
  const classes = useStyles();
  const [showAnulado, setShowAnulado] = useState(false);

  //----
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //---
  const eliminarPago  = () =>{
    let pag = history.location.state.confirmDialog;
    props.anularPago(pag.conId,pag.pagId)
  };
  const {pagos=[]} = props;
  const pagosActive = pagos.filter(p=>!p.pagAnulado && p.pagTipo !== 4)


  const totalMonto = pagosActive.reduce((a, b) => +a + +b.pagMonto, 0);
  const totalMora = pagosActive.reduce((a, b) => +a + +b.pagMora, 0);
  const totalDescuento = pagosActive.reduce((a, b) => +a + +b.pagDescuento, 0);
  const totalPago = totalMonto + totalMora - totalDescuento;


    return (
      <Fragment>
        <div className={classes.barTitle}>
          <Typography color={"secondary"}>{Strings.pagos}</Typography>
          <FormControlLabel className="d-print-none"
            control={
              <Switch
                //disableRipple
                  size={"small"}
                  color={"secondary"}
                checked={showAnulado}
                onChange={()=>setShowAnulado(!showAnulado)}
                value="checkedB"
              />
            }
            label={Strings.anulado}
          />
        </div >
        <TableContainer >
          <Table className={"hasOnclick"}>
            <TableHead>
              <TableRow>
                <TableCell className={"d-print-none"}/>
                <TableCell>{Strings.cuota}</TableCell>
                <TableCell>{Strings.fecha}</TableCell>
                <TableCell>{Strings.monto}</TableCell>
                <TableCell>{Strings.mora}</TableCell>
                <TableCell>{Strings.descuento}</TableCell>
                <TableCell>{Strings.totalPagado}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                if(!n.pagAnulado || showAnulado ){
                  return (
                      <TableRow key={n.pagId} className={n.pagAnulado?classes.pagAnulado:n.pagTipo === 4?classes.pagAjuste:""} onClick={()=> history.push(history.location.pathname,{recibo:n})}>

                        <TableCell className={"actionCell"}>
                          {hasPermissionTo(PERSMISOS.pagosCrear) && n.anulable &&
                            <IconButton
                                onClick={(e)=>{e.stopPropagation(); history.push(history.location.pathname,{confirmDialog:n})}}
                                disabled={Boolean(n.pagAnulado)}>
                              <Close className={"text-red"}/>
                            </IconButton>
                          }
                        </TableCell>
                        <TableCell component="th" scope="row">{n.pagCuota}</TableCell>
                        <TableCell>{formatDate(n.pagFecha)}</TableCell>

                        <TableCell>{formatPeso(n.pagMonto)}</TableCell>
                        <TableCell>{formatPeso(n.pagMora)}</TableCell>
                        <TableCell>{formatPeso(n.pagDescuento)} </TableCell>
                        <TableCell>{formatPeso(n.pagTotal)}</TableCell>
                      </TableRow>
                  );
                }else{return false}
              })}
            </TableBody>
            <TableFooter>
                <TableRow className={""}>
                <TableCell colSpan={3}><Typography align={"right"}>{Strings.total}:</Typography></TableCell>
                <TableCell>{formatPeso(totalMonto)}</TableCell>
                <TableCell>{formatPeso(totalMora)}</TableCell>
                <TableCell>{formatPeso(totalDescuento)}</TableCell>
                <TableCell>{formatPeso(totalPago)}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer >
        {pagos.length > 10 &&
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={pagos.length}
              rowsPerPage={rowsPerPage}
              SelectProps={{
                inputProps: {'aria-label': 'No. registros'},
                native: true,
              }}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
        <ConfirmDialog onConfirm={eliminarPago}/>
        <ReciboPago/>

      </Fragment>
    );

}


const mapStateToProps = (state) => ({
  isPending: state.pagos.isPending,
});
export default connect(mapStateToProps, {anularPago})(Pagos);
