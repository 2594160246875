import React, { useEffect,Fragment} from 'react';
import { connect } from 'react-redux';
import { mostrarEnums} from '../../redux/actions/enumsActions';
import AppBar from "../../components/appBar";
import _ from "lodash"
import {
    Typography, TableContainer, Table, TableBody, TableCell, TableRow,
} from "@material-ui/core";
import Strings from "../../assets/strings"

function Enums(props) {
    const { mostrarEnums } = props
     useEffect(() => {
        mostrarEnums();
     }, [mostrarEnums]);

     const { title: mainTitle, enums: { list, isFetching} } = props;
     const isEmpty = !isFetching && Object.keys(list).length === 0

      return (
          <>
              <AppBar title={mainTitle}></AppBar>
              <section className="contentInner">
                      <div className="table-responsive">
                          {isFetching && !list.length &&
                              <div className="loading"></div>
                          }

                          <TableContainer>
                              <Table aria-label="simple table">
                                  <TableBody>
                                      {!isEmpty && Object.keys(list).map((property,i) =>
                                          <Fragment key={i}>
                                              <TableRow>
                                                  <TableCell><Typography variant={"body1"} color={"primary"}>{_.startCase(property).toUpperCase()}</Typography> </TableCell>
                                                  <TableCell>{property}</TableCell>
                                              </TableRow>
                                              {list[property].map(item=>
                                                  <TableRow key={item.key}>
                                                      <TableCell> - {Strings[_.camelCase(item.value)] || <span className="text-red">{item.value}</span> }</TableCell>
                                                      <TableCell>{item.key}</TableCell>
                                                      {/*<TableCell> {item.value}:"{item.value}",</TableCell>*/}
                                                  </TableRow>
                                              )}
                                          </Fragment>
                                      )}

                                  </TableBody>
                              </Table>
                          </TableContainer>


                          {isEmpty && <div className="isEmpty">No se encontraron registros</div>}
                      </div>

              </section>
          </>
        );

}

const mapStateToProps = (state) => ({
    enums: state.enums
})
export default connect(mapStateToProps, { mostrarEnums })(Enums);
